export const pool_list = [
  {
    "id": 0,
    "symbol0": "STAR",
    "symbol1": "ASTR",
    "address": "0x4a0e3b2A0c35737d1c2A78FB76470ce31836024c",
    "poolId": 1,
  },  
  {
    "id": 1,
    "symbol0": "ASTR",
    "symbol1": "USDC",
    "address": "0x18d1C8eF07B05c1Be3b4569E9E1d5A24300C6b3F",
    "poolId": 2,
  },
  {
    "id": 2,
    "symbol0": "ASTR",
    "symbol1": "USDT",
    "address": "0xCA426A12A4ff20b60510c328c4C255fF37751007",
    "poolId": 3,
  },
  {
    "id": 3,
    "symbol0": "DOT",
    "symbol1": "ASTR",
    "address": "0x19644B8931bca077B72a0ea0477888C3a4E3a241",
    "poolId": 4,
  },
  {
    "id": 4,
    "symbol0": "USDT",
    "symbol1": "USDC",
    "address": "0xA04FCE6AA30474034D5Fd601098D15F08a5110a3",
    "poolId": 5,
  },
  {
    "id": 5,
    "symbol0": "DAI",
    "symbol1": "USDC",
    "address": "0x25E2abFD444043571CD64BA6557905f252595142",
   "poolId": 6,
  },
  {
    "id": 6,
    "symbol0": "DAI",
    "symbol1": "USDT",
    "address": "0x9fA1073ADe274995662eD6F943C7FC0fd1a82546",
   "poolId": 7,
  },
  {
    "id": 7,
    "symbol0": "DAI",
    "symbol1": "ASTR",
    "address": "0x1FE926234C1De2Ea97bdc8b62D84C6b11EC7b278",
    "poolId": 8,
  },
  {
    "id": 8,
    "symbol0": "WBTC",
    "symbol1": "ASTR",
    "address": "0x9546100eD35c7c3BC53CE1A5877740ab281AfCDC",
    "poolId": 9,
  },
  {
    "id": 9,
    "symbol0": "WETH",
    "symbol1": "ASTR",
    "address": "0x8C418a7bedE8270645D7D96bDa983803c57e22f2",
    "poolId": 10,
  },
  {
    "id": 10,
    "symbol0": "BUSD",
    "symbol1": "USDC",
    "address": "0x01a4Ed6EE449816b75f9A61dC6C0ee0B3c521e1B",
    "poolId": 11,
  },
  {
    "id": 11,
    "symbol0": "BNB",
    "symbol1": "ASTR",
    "address": "0xc768F8DC8EA0be0E4C8DA52CB2296B884ED92bd4",
    "poolId": 12,
  },
  {
    "id": 12,
    "symbol0": "SDN",
    "symbol1": "ASTR",
    "address": "0xe61fa5d2fC0ef91f0e6fa030dFEf74fc1A5f8194",
    "poolId": 13,
  }
]
