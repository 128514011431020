import Vue from 'vue'
import Vuex from 'vuex'
import { BigNumber } from 'bignumber.js'
import Web3 from "web3/lib"
import axios from 'axios'
import Multicall from '@dopex-io/web3-multicall';

import {whiteList} from '../constants/whitelist.js'
import {whiteList1} from '../constants/whitelist1.js'
import keccak256 from 'keccak256'
import { MerkleTree } from 'merkletreejs'

BigNumber.config({ EXPONENTIAL_AT: 100 })

Vue.use(Vuex)

import abiERC20 from '@/abi/ERC20.json'
import abiERC721Enumerable from '@/abi/ERC721Enumerable.json'
import abiSTAR from '@/abi/starSwapToken.json'
import abiRouter from '@/abi/starSwapRouter.json'
import abiFactory from '@/abi/starSwapFactory.json'
import abiPair from '@/abi/starSwapPair.json'
import abiMaster from '@/abi/masterChef.json'
import abiStarCardsNFT from '@/abi/starCardsNFT.json'
import abiSkywalkersNFT from '@/abi/skywalkersNFT.json'
import abiNFTStaking from '@/abi/nftStaking.json'
import abiStarCardsDistributor from '@/abi/starCardsDistributor.json'
import abiLiquidityGenerator from '@/abi/liquidityGenerator.json'
import abiDistributor from '@/abi/distributor.json'
import abiExchanger from '@/abi/starSwapExchanger.json'
import abiExchangerEx from '@/abi/starSwapExchangerEx.json'
import abiLottery from '@/abi/starSwapLottery.json'
import abiTreasury from '@/abi/starSwapTreasury.json'
import abiStarCardsGovernance from '@/abi/starCardsGovernance.json'

import abiIWETH from '@/abi/IWETH.json'
import {token_list} from '@/constants/token.js'
import {staking_list} from '@/constants/staking.js'
import {pool_list} from '@/constants/pool.js'

import hrData from "@/api/24hr.json";
import pairData from "@/api/pairs.json";
import summaryData from "@/api/summary.json";

const UINT256_MAX = '115792089237316195423570985008687907853269984665640564039457584007913129639935'
const ADDR_NULL = '0x0000000000000000000000000000000000000000';

const MAX_TOKEN_COUNT = 3000;
const MAX_TOKEN_SUB_COUNT = 1000;
const MSX_TOKEN_COUNT_ACCOUNT = 15;
const STARCARDS_FLOOR_PRICE = 1450;

const ADDR_MULTICALL = '0x16F4aA0A0665F36864113bB6bdAb8965E60Ba6d1'
const ADDR_ASTR = '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720';
const ADDR_STAR = '0x8489f4554790F5A103F2B0398537eAEe68B73884';
const ADDR_ROUTER = '0xF4e6251852144B9b3e268a175FcE8C0C47e6419E';
const ADDR_FACTORY = '0x0b657e81a0C3E903cbe1228579fBd49AC5D81Ac1';
const ADDR_MASTER = '0xEa48D4243223200405D93D7eaC32D38398b959ec';
  
const ADDR_DISTRIBUTOR = '0x465445c785872c8ba64Ea42e3A5dAFCC40A898a6';
const ADDR_LIQUIDITY_GENERATOR = '0x6278b4c63fe83703E5007aF61F80FDA0C73F21bA';
const ADDR_EXCHANGER = '0x09618249DDB333c546352E0957F6F2D37623165f';
const ADDR_EXCHANGEREX = '0x518Db8Be795f17e658D6828F305CB7f8C72C568c';
const ADDR_TREASURY = '0x9B1dB4D59cD001741f0DAFe2Dd327392cc8c600A';
const ADDR_LOTTERY = '0x772bbD419f347E01c678841FA20593A39Fc4540f';

const ADDR_SKYWALKERS_NFT = '0x7b458a1aA7d42A74c6f96E45797358c1FDDD496C';
const ADDR_NFT_STAKING = '0x95A05Fa09EFE8EEa4Fa72774287210aE471365fe';
const ADDR_STARCARDS_DISTRIBUTOR = '0x38a37e2b4ce08E8D6d124b1B795768c26b8afc65';

const ADDR_STARCARDS_NFT = '0xDECA4Af02ecD70a82D0E810D707E4fEB53C25206';
const ADDR_STARCARDS_GOVERNANCE = '0x381E82f85Ff1f92d313C45A454ea648CC65A34AF';

const ADDR_OWNER = '0x3876577f7bD01411c932e3fBa32b28421EF7Ef5E';

export default new Vuex.Store({
  state: {
    account: null,
    messageContent: null,
    messageType: null,
    approvedOneTokenRouter: false,
    approvedTwoTokenRouter: false,
    approvedPairRouter: false,
    approvedPairMaster: [],
    approvedStakingPairMaster: [],
    approvedNFTStaking: [],
    approvedLottery: false,
    approvedPairTreasury: false,
    approvedSTARTreasury: false,
    contracts: {
      multiCall: null,
      tokenSTAR: null,
      tokenRouter: null,
      tokenFactory: null,
      tokenMaster: null,
      tokenStarCardsNFT: null,
      tokenNFTStaking: null,
      tokenStarCardsDistributor: null,
      tokenSkywalkersNFT: null,
      tokenExchanger: null,
      tokenExchangerEx: null,
      tokenLiquidityGenerator: null,
      tokenDistributor: null,
      tokenLottery: null,
      tokenTreasury: null,
      tokenDao: null
    },
    settings: {
      slippage: 500,
      deadline: 30,
      gasPrice: 12000000000
    },
    api: {
      hr:null,
      pairs:null,
      summary:null
    },
    address: {
      ASTR: null
    },
    balance: {
      amountFrom: BigNumber,
      amountTo: BigNumber,
      amountASTR: BigNumber,
      amountSTAR: BigNumber,
      circulatingSupply: BigNumber
    },
    price: {
      STAR: BigNumber,
      ASTR: BigNumber
    },
    tokenTotalSupply: {
      STAR: BigNumber
    },
    jumbotron: {
      totalSupply: BigNumber,
      totalFTMRewards: BigNumber,
      totalWinnings: BigNumber,
      totalPlayers: BigNumber,
      totalSTARRewards: BigNumber,
      totalSTARHolders: BigNumber,
      totalSTARStaked: BigNumber
    },
    liquidity: {
      amountOne: BigNumber,
      amountTwo: BigNumber,
      rateOne2Two: BigNumber,
      balance: BigNumber,
      tokenBalance: [],
      tokenDecimals: [],
      totalSupply: BigNumber,
      lpToken: null,
      coinOne: null,
      coinTwo: null,
      tokenMap: null,
      poolMap: null,
      tvl: [],
      farm_list: [],
      tokenInfo: [],
      crossOneRouter: false,
      crossOneTokenSymbol: null,
      crossOneTokenAddress: null,
      crossTwoRouter: false,
      crossTwoTokenSymbol: null,
      crossTwoTokenAddress: null,
      pairInfo: []
    },
    pool: {
      liquiditybalance: [],
      tokenbalance: [],
      totalSupply: [],
      reserved0: [],
      reserved1: [],
      decimals0: [],
      decimals1: [],
      allocpoint: [],
    },
    farming: {
      liquiditybalance: [],
      tokenbalance: [],
      balance: [],
      pendingSTAR: [],
      claimableSTAR: [],
      earnedSTAR: [],
      totalSupply: [],
      depositInfo: null,
      claimableTokenInfo: [],
      taxInfo: [],
      totalAllocPoint: null,
      allocpoint: [],
      currentOpenedPoolIndex: null,
      reserved0: [],
      reserved1: [],
      decimals0: [],
      decimals1: [],
      tvl: [],
    },
    staking: {
      balance: [],
      totalSupply: [],
      pendingSTAR: [],
      claimableSTAR: [],
      earnedSTAR: [],
      depositInfo: [],
      claimableTokenInfo: [],
      taxInfo: [],
      tokenBalance: [],
      tokenDecimals: [],
      allocpoint: [],
    },
    nftStaking: {
      currentNFTCollection: 0,
      claimableReward: BigNumber,
      claimedReward: BigNumber,
      unStakedtokenIds: [],
      unStakedtokenURIs:[],
      unStakedimageURIs:[],
      unStakedtokenNames:[],
      unStakedtokenAttributes:[],
      stakedtokenIds: [],
      stakedtokenPrices:[],
      stakedtokenURIs:[],
      stakedimageURIs:[],
      stakedtokenNames:[],
      stakedtokenAttributes:[],
      searchUnStakedResult:false,
      searchStakedResult:false,
      poolInfo: [],
      userStakedNFT:0,
      pools: [],
      collectionNames: []
    },
    starCardsnft: {
      maxSubTokenCount: Number,
      maxTotalTokenCount: Number,
      maxTokenCountOfAccount: Number,
      subTotalSupply: Number,
      totalSupply: Number,
      price: BigNumber,
      status: null,
      tokenIds: [],
      tokenPrices:[],
      tokenURIs:[],
      imageURIs:[],
      tokenNames:[],
      tokenAttributes:[],
      searchResult:false,
      claimableRewards: BigNumber,
      claimedRewards: BigNumber,
      floorPrice: null
    },
    starCardsGovernance: {
      proposalCount: Number,
      proposalInfo: [],
      status: [],
      currentVoters: [],
      currentProposal: null,
      owner: null
    },
    skywalkersnft: {
      maxSubTokenCount: Number,
      maxTotalTokenCount: Number,
      maxTokenCountOfAccount: Number,
      subTotalSupply: Number,
      totalSupply: Number,
      price: BigNumber,
      status: null,
      tokenIds: [],
      tokenPrices:[],
      tokenURIs:[],
      imageURIs:[],
      tokenNames:[],
      tokenAttributes:[],
      searchResult:false,
    },
    liquiditygenerator: {
      totalASTRShares: BigNumber,
      normalVestingSTAR: BigNumber,
      vestingEnd: BigNumber,
      vestingBegin: BigNumber,
      periodEnd: BigNumber,
      periodBegin: BigNumber,
      periodDuration: BigNumber,
      starPriceInLGE: BigNumber,
      recipientInfo: null,
      claimableRewards: null,
    },
    exchanger: {
      totalASTRShares: BigNumber,
      normalVestingSTAR: BigNumber,
      vestingEnd: BigNumber,
      vestingBegin: BigNumber,
      periodEnd: BigNumber,
      periodBegin: BigNumber,
      periodDuration: BigNumber,
      starPriceInLGE: BigNumber,
      recipientInfo: null,
      claimableRewards: null,
      airdroppableRewards: null,
      maxASTRDeposit: BigNumber,
      minASTRDeposit: BigNumber,
      totalRemainedSTAR: null
    },
    exchangerEx: {
      totalRewards: BigNumber,
      claimedRewards: BigNumber,
      claimableRewards: BigNumber,
    },
    lottery: {
      currentLotteryId: 0, 
      currentLotteryState: 0,
      winningPrize: BigNumber,
      nextDraw: Date,
      myWinning: null,
      lastAllResult: null,
      lastAllResultId: 0,
      lastMyResult: null,
      myResultIndex: 0,
      myResultIds: null,
      price: BigNumber,
      lastMyNumbers: [],
      maxRewardPerClaim: BigNumber,
      winningMine: BigNumber
    },
    treasury: {
      lpBalance: BigNumber,
      totalASTRFund: BigNumber,
      totalLPFund: BigNumber,
      treasuryBalance: BigNumber,
      priceSTAR: BigNumber,
      priceLP: BigNumber,
      bakingPriceSTAR: BigNumber,
      balanceSTARforASTR: BigNumber,
      balanceSTARforLP: BigNumber,
      pendingSTAR: [],
      remainedSTAR0: BigNumber,
      remainedSTAR1: BigNumber,
      totalFund0:BigNumber,
      totalFund1:BigNumber,
      totalPhurchasedSTAR: [],
      totalEarnedSTAR: [],
      totalSelledSTAR: BigNumber,
      totalEarnedASTR: BigNumber,
      isEnableBuyBack: null,
      purchasedInfoByASTR: [],
      purchasedInfoByLP: []
    },
    global: {
      loadingTransaction:false,
      loadingNFTs:false,
      transactionHash:null
    }
  },
  mutations: {
    async init(state) {
      state.contracts.multiCall = new Multicall({
        multicallAddress: ADDR_MULTICALL,
        provider: window.provider,
      });

      state.contracts.tokenSTAR = new window.web3.eth.Contract(abiSTAR, ADDR_STAR);
      state.contracts.tokenRouter = new window.web3.eth.Contract(abiRouter, ADDR_ROUTER);
      state.contracts.tokenFactory = new window.web3.eth.Contract(abiFactory, ADDR_FACTORY);
      state.contracts.tokenMaster = new window.web3.eth.Contract(abiMaster, ADDR_MASTER);

      state.contracts.tokenStarCardsNFT = new window.web3.eth.Contract(abiStarCardsNFT, ADDR_STARCARDS_NFT);
      state.contracts.tokenNFTStaking = new window.web3.eth.Contract(abiNFTStaking, ADDR_NFT_STAKING);
      state.contracts.tokenStarCardsDistributor = new window.web3.eth.Contract(abiStarCardsDistributor, ADDR_STARCARDS_DISTRIBUTOR);

      state.contracts.tokenSkywalkersNFT = new window.web3.eth.Contract(abiSkywalkersNFT, ADDR_SKYWALKERS_NFT);

      state.contracts.tokenLiquidityGenerator = new window.web3.eth.Contract(abiLiquidityGenerator, ADDR_LIQUIDITY_GENERATOR);
      state.contracts.tokenDistributor = new window.web3.eth.Contract(abiDistributor, ADDR_DISTRIBUTOR);
      state.contracts.tokenExchanger = new window.web3.eth.Contract(abiExchanger, ADDR_EXCHANGER);
      state.contracts.tokenExchangerEx = new window.web3.eth.Contract(abiExchangerEx, ADDR_EXCHANGEREX);

      state.contracts.tokenLottery = new window.web3.eth.Contract(abiLottery, ADDR_LOTTERY);
      state.contracts.tokenTreasury = new window.web3.eth.Contract(abiTreasury, ADDR_TREASURY);

      state.contracts.tokenDao = new window.web3.eth.Contract(abiStarCardsGovernance, ADDR_STARCARDS_GOVERNANCE);

      state.address.ASTR = ADDR_ASTR;

      if (state.liquidity.coinOne == null)
        state.liquidity.coinOne = token_list[0];

      if (state.liquidity.coinTwo == null)
        state.liquidity.coinTwo = token_list[2];

      state.liquidity.tokenMap = new Map();

      const params = {
        'vs_currency': 'usd',
        'localization': false,
        'sparkline': false
      };
      let length = token_list.length;
      for (let i=0; i<length; i++) {
        if (token_list[i].coingeckoid != "") {
          axios.get('https://api.coingecko.com/api/v3/coins/'+ token_list[i].coingeckoid, {params})
          .then(response => ( 
            state.liquidity.tokenInfo[i] = response.data))
          .catch(error => console.log(error))
          .finally(() => token_list[i].price = state.liquidity.tokenInfo[i].market_data.current_price.usd)
        }

        state.liquidity.tokenMap.set(token_list[i].symbol, token_list[i]);
      }

      length = pool_list.length;
      let index = 0;
      for (let i=0; i<length; i++) {
        let poolId = pool_list[i].poolId;
        if (poolId >= 0) {
          state.liquidity.farm_list[index] = pool_list[i];
          index++;
        }
      }

      for (let i=0; i<length; i++) {
        axios.get('https://api.dexscreener.com/latest/dex/pairs/astar/' + pool_list[i].address)
        .then(response => ( 
          state.liquidity.pairInfo[i] = response.data
          ))
        .catch(error => console.log(error))
        .finally()
      }
    },
    async initProc(state) {
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenMaster.methods.totalAllocPoint(),
        state.contracts.tokenStarCardsNFT.methods.max_TotalStarCards(),
        state.contracts.tokenStarCardsNFT.methods.max_SubStarCards(),
        state.contracts.tokenStarCardsNFT.methods.max_TokenCountOfAccount(),
        state.contracts.tokenSkywalkersNFT.methods.max_TotalTokenCount(),
        state.contracts.tokenSkywalkersNFT.methods.max_SubTokenCount(),
        state.contracts.tokenSkywalkersNFT.methods.max_TokenCountOfAccount(),

        state.contracts.tokenDistributor.methods.vestingBegin(),
        state.contracts.tokenDistributor.methods.vestingEnd(),
        state.contracts.tokenLiquidityGenerator.methods.periodBegin(),
        state.contracts.tokenLiquidityGenerator.methods.periodEnd(),
        state.contracts.tokenExchanger.methods.depositBegin(),
        state.contracts.tokenExchanger.methods.MAX_ASTR_DEPOSIT(),
        state.contracts.tokenExchanger.methods.MIN_ASTR_DEPOSIT(),

        state.contracts.tokenLottery.methods.maxRewardPerClaim(),
        state.contracts.tokenSTAR.methods.totalSupply(),
        state.contracts.tokenSTAR.methods.balanceOf(ADDR_OWNER)
      ]);

      state.farming.totalAllocPoint = result[0];
      state.starCardsnft.maxTotalTokenCount = Number(result[1]);
      state.starCardsnft.maxSubTokenCount = Number(result[2]);
      state.starCardsnft.maxTokenCountOfAccount = Number(result[3]);
      state.skywalkersnft.maxTotalTokenCount = Number(result[4]);
      state.skywalkersnft.maxSubTokenCount = Number(result[5]);
      state.skywalkersnft.maxTokenCountOfAccount = Number(result[6]);

      state.liquiditygenerator.vestingBegin = BigNumber(result[7] * 1000);
      state.liquiditygenerator.vestingEnd = result[8] * 1000;
      state.liquiditygenerator.periodBegin = result[9] * 1000;
      state.liquiditygenerator.periodEnd = result[10] * 1000;
      state.exchanger.periodBegin = result[11] * 1000;
      state.exchanger.maxASTRDeposit = BigNumber(result[12]).shiftedBy(-18);
      state.exchanger.minASTRDeposit = BigNumber(result[13]).shiftedBy(-18);

      state.lottery.maxRewardPerClaim = BigNumber(result[14]);

      state.tokenTotalSupply.STAR = BigNumber(result[15]).shiftedBy(-18);

      state.balance.circulatingSupply = BigNumber(result[15]).minus(BigNumber(result[16])).shiftedBy(-18);
    },
    set_account(state,account) {
      state.account = account;
    },
    show_info(state,message) {
      state.messageContent = message;
      state.messageType = 'info';
    },
    show_success(state,message) {
      state.messageContent = message;
      state.messageType = 'success';
    },
    show_error(state,message) {
      state.messageContent = message;
      state.messageType = 'error';
    },
    show_warning(state,message) {
      state.messageContent = message;
      state.messageType = 'warning';
    },
    async check_approve(state,account) {
      var calls = [];

      // check farming approve
      let length = state.liquidity.farm_list.length;
      for (let i=0; i<length; i++) {
        let lpContract = new window.web3.eth.Contract(abiERC20, state.liquidity.farm_list[i].address);
        calls.push(lpContract.methods.allowance(account.address, ADDR_MASTER));
      }

      var result = await state.contracts.multiCall.aggregate(calls);
      for (let i=0; i<length; i++) {
        if (result[i] > 0)
          state.approvedPairMaster[i] = true;
        else
          state.approvedPairMaster[i] = false;
      }

      // check staking approve
      length = staking_list.length;
      calls = [];
      for (let i=0; i<length; i++) {
        let lpContract = new window.web3.eth.Contract(abiERC20, staking_list[i].address);
        calls.push(lpContract.methods.allowance(account.address, ADDR_MASTER));
      }

      result = await state.contracts.multiCall.aggregate(calls);
      for (let i=0; i<length; i++) {
        if (result[i] > 0)
          state.approvedStakingPairMaster[i] = true;
        else
          state.approvedStakingPairMaster[i] = false;
      }

      result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenTreasury.methods.lpToken()
      ]);
      let lpContract = new window.web3.eth.Contract(abiPair, result[0]);

      result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenSTAR.methods.allowance(account.address, ADDR_LOTTERY),
        state.contracts.tokenSTAR.methods.allowance(account.address, ADDR_TREASURY),
        lpContract.methods.allowance(account.address, ADDR_TREASURY)
      ]);

      if (result[0] > 0)
        state.approvedLottery = true;
      else
        state.approvedLottery = false;

      if (result[1] > 0)
        state.approvedSTARTreasury = true;
      else
        state.approvedSTARTreasury = false;

      if (result[2] > 0)
        state.approvedPairTreasury = true;
      else
        state.approvedPairTreasury = false;

      //Get NFT staking information
      result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenNFTStaking.methods.getPoolCount()
      ]);

      calls = [];
      length = result[0];
      for (let i=0; i<length; i++) {
        calls.push(state.contracts.tokenNFTStaking.methods.poolInfo(i));
      }

      result = await state.contracts.multiCall.aggregate(calls);
      calls = [];
      for (let i=0; i< length; i++) {
        let nftContract = new window.web3.eth.Contract(abiERC721Enumerable, result[i][0]);
        calls.push(nftContract.methods.isApprovedForAll(account.address, ADDR_NFT_STAKING));
      }
      result = await state.contracts.multiCall.aggregate(calls);
      for (let i=0; i< length; i++) {
        state.approvedNFTStaking[i] = result[i]
      }
    },
    async read_globalInfo(state) {
      // Get all token's balances
      var calls = [];
      let length = token_list.length;
      let indexofStar;
      state.liquidity.tokenDecimals[0] = token_list[0].decimals;
      for (let i=0; i<length; i++) {
        if (state.account) {
          if (i == 0) {
            // Get ASTR token balance
            window.web3.eth.getBalance(state.account.address).then(ret=>{
              state.liquidity.tokenBalance[i] = BigNumber(ret);
              state.balance.amountASTR = BigNumber(ret);
            })
          }
          else {
            let toTokenContract = new window.web3.eth.Contract(abiERC20, token_list[i].address);
            calls.push(toTokenContract.methods.balanceOf(state.account.address));
            if (token_list[i].symbol == "STAR") {
              indexofStar = i;
            }
          }
        }
        else
          state.liquidity.tokenBalance[i] = BigNumber(0);

        state.liquidity.tokenDecimals[i] = token_list[i].decimals;
      }

      var result = await state.contracts.multiCall.aggregate(calls);
      for (let i=0; i<length-1; i++) {
        if (state.account)
          state.liquidity.tokenBalance[i+1] = BigNumber(result[i]);
      }

      if (indexofStar > 0)
        state.balance.amountSTAR = BigNumber(result[indexofStar-1]);

      // Get global staking information
      calls = [];
      length = staking_list.length;
      for (let i=0; i<length; i++) {
        let decimal = state.liquidity.tokenMap.get(staking_list[i].symbol).decimals;
        state.staking.tokenDecimals[i] = decimal;

        calls.push(state.contracts.tokenMaster.methods.poolInfo(staking_list[i].poolId));
      }

      result = await state.contracts.multiCall.aggregate(calls);
      for (let i=0; i<length; i++) {
        state.staking.allocpoint[i] = result[i][1];
        state.staking.totalSupply[i] = BigNumber(result[i][6]);
      }

      // Get user's staking information
      calls = [];
      length = staking_list.length;
      for (let i=0; i<length; i++) {
        if (state.account) {
          let poolId = staking_list[i].poolId;
          calls.push(state.contracts.tokenMaster.methods.userInfo(poolId, state.account.address));
          calls.push(state.contracts.tokenMaster.methods.pendingSTAR(poolId,state.account.address));

          let tokenContract = new window.web3.eth.Contract(abiERC20, staking_list[i].address);
          calls.push(tokenContract.methods.balanceOf(state.account.address));
        }
        else {
          state.staking.balance[i] = BigNumber(0);
          state.staking.earnedSTAR[i] = BigNumber(0);
          state.staking.pendingSTAR[i] = BigNumber(0);
          state.staking.claimableSTAR[i] = BigNumber(0);
          state.staking.tokenBalance[i] = BigNumber(0);
        }
      }

      result = await state.contracts.multiCall.aggregate(calls);
      for (let i=0; i< length; i++) {
        if (state.account) {
          state.staking.balance[i] = BigNumber(result[i*3][0]);
          state.staking.earnedSTAR[i] = BigNumber(result[i*3][2]);
          state.staking.pendingSTAR[i] = BigNumber(result[i*3+1][0]);
          state.staking.claimableSTAR[i] = BigNumber(result[i*3+1][1]);
          state.staking.tokenBalance[i] = BigNumber(result[i*3+2]);
        }
      }

      // Get farming information
      length = state.liquidity.farm_list.length;
      calls = [];
      for (let i=0; i<length; i++) {
        let poolId = state.liquidity.farm_list[i].poolId;
        calls.push(state.contracts.tokenMaster.methods.poolInfo(poolId));
        let lpContract = new window.web3.eth.Contract(abiPair, state.liquidity.farm_list[i].address);
        calls.push(lpContract.methods.totalSupply());
      }

      // Get global farming information
      result = await state.contracts.multiCall.aggregate(calls);

      for (let i=0; i<length; i++) {
          state.farming.totalSupply[i] = BigNumber(result[i*2][6]);
          state.farming.allocpoint[i] = result[i*2][1];
          state.farming.liquiditybalance[i] = BigNumber(result[i*2+1]);
      }

      // Get farming information
      calls = [];
      for (let i=0; i<length; i++) {
        let lpContract = new window.web3.eth.Contract(abiPair, state.liquidity.farm_list[i].address);
        calls.push(lpContract.methods.getReserves());
      }

      result = await state.contracts.multiCall.aggregate(calls);
      for (let i=0; i<length; i++) {
        let tokenInfo = state.liquidity.tokenMap.get(state.liquidity.farm_list[i].symbol0);
        let tokenInfo1 = state.liquidity.tokenMap.get(state.liquidity.farm_list[i].symbol1);
        let decimal0 = state.liquidity.tokenMap.get(state.liquidity.farm_list[i].symbol0).decimals * (-1);
        let decimal1 = state.liquidity.tokenMap.get(state.liquidity.farm_list[i].symbol1).decimals * (-1);

        state.farming.decimals0[i] = decimal0;
        state.farming.decimals1[i] = decimal1;

        if (tokenInfo.address.toLowerCase() < tokenInfo1.address.toLowerCase()) {
          state.farming.tvl[i] = BigNumber(result[i][0]).shiftedBy(decimal0)
                          .times(tokenInfo.price)
                          .plus(
                          BigNumber(result[i][1]).shiftedBy(decimal1)
                          .times(tokenInfo1.price));

          state.farming.reserved0[i] = result[i][0];
          state.farming.reserved1[i] = result[i][1];
        }
        else {
          state.farming.tvl[i] = BigNumber(result[i][1]).shiftedBy(decimal0)
                          .times(tokenInfo.price)
                          .plus(
                          BigNumber(result[i][0]).shiftedBy(decimal1)
                          .times(tokenInfo1.price));

          state.farming.reserved0[i] = result[i][1];
          state.farming.reserved1[i] = result[i][0];
        }
      }

      // Get user's farming information
      calls = [];
      for (let i=0; i<length; i++) {
        if (state.account) {
          let poolId = state.liquidity.farm_list[i].poolId;
          let lpContract = new window.web3.eth.Contract(abiPair, state.liquidity.farm_list[i].address);

          calls.push(lpContract.methods.balanceOf(state.account.address));
          calls.push(state.contracts.tokenMaster.methods.userInfo(poolId, state.account.address));
          calls.push(state.contracts.tokenMaster.methods.pendingSTAR(poolId,state.account.address));
        }
        else {
          state.farming.tokenbalance[i] = BigNumber(0);
          state.farming.balance[i] = BigNumber(0);
          state.farming.earnedSTAR[i] = BigNumber(0);
          state.farming.pendingSTAR[i] = BigNumber(0);
          state.farming.claimableSTAR[i] = BigNumber(0);
        }
      }

      result = await state.contracts.multiCall.aggregate(calls);
      for (let i=0; i< length; i++) {
        if (state.account) {
          state.farming.tokenbalance[i] = BigNumber(result[i*3]);
          state.farming.balance[i] = BigNumber(result[i*3+1][0]);
          state.farming.earnedSTAR[i] = BigNumber(result[i*3+1][2]);
          state.farming.pendingSTAR[i] = BigNumber(result[i*3+2][0]);
          state.farming.claimableSTAR[i] = BigNumber(result[i*3+2][1]);
        }
      }

      // Get pool information
      length = pool_list.length;
      calls = [];
      let index = 0;
      for (let i=0; i<length; i++) {
        let poolId = pool_list[i].poolId;
        if (poolId >= 0) {
          index++;
          calls.push(state.contracts.tokenMaster.methods.poolInfo(poolId));
        }
      }
      result = await state.contracts.multiCall.aggregate(calls);
      for (let i=0; i<index; i++) {
          state.pool.totalSupply[i] = BigNumber(result[i][6]);
          state.pool.allocpoint[i] = result[i][1];
      }

      calls = [];
      for (let i=0; i<length; i++) {
        let lpContract = new window.web3.eth.Contract(abiPair, pool_list[i].address);
        calls.push(lpContract.methods.totalSupply());
      }
      result = await state.contracts.multiCall.aggregate(calls);
      for (let i=0; i<length; i++) {
        state.pool.liquiditybalance[i] = BigNumber(result[i]);
      }

      calls = [];
      for (let i=0; i<length; i++) {
        let lpContract = new window.web3.eth.Contract(abiPair, pool_list[i].address);
        calls.push(lpContract.methods.getReserves());
      }

      result = await state.contracts.multiCall.aggregate(calls);
      for (let i=0; i<length; i++) {
        let tokenInfo = state.liquidity.tokenMap.get(pool_list[i].symbol0);
        let tokenInfo1 = state.liquidity.tokenMap.get(pool_list[i].symbol1);
        let decimal0 = state.liquidity.tokenMap.get(pool_list[i].symbol0).decimals * (-1);
        let decimal1 = state.liquidity.tokenMap.get(pool_list[i].symbol1).decimals * (-1);

        state.pool.decimals0[i] = decimal0;
        state.pool.decimals1[i] = decimal1;

        if (tokenInfo.address.toLowerCase() < tokenInfo1.address.toLowerCase()) {
          state.liquidity.tvl[i] = BigNumber(result[i][0]).shiftedBy(decimal0)
                          .times(tokenInfo.price)
                          .plus(
                          BigNumber(result[i][1]).shiftedBy(decimal1)
                          .times(tokenInfo1.price));

          state.pool.reserved0[i] = result[i][0];
          state.pool.reserved1[i] = result[i][1];
        }
        else {
          state.liquidity.tvl[i] = BigNumber(result[i][1]).shiftedBy(decimal0)
                          .times(tokenInfo.price)
                          .plus(
                          BigNumber(result[i][0]).shiftedBy(decimal1)
                          .times(tokenInfo1.price));

          state.pool.reserved0[i] = result[i][1];
          state.pool.reserved1[i] = result[i][0];
        }
      }
      // Get user's pool information
      calls = [];
      for (let i=0; i<length; i++) {
        if (state.account) {
          let lpContract = new window.web3.eth.Contract(abiPair, pool_list[i].address);
          calls.push(lpContract.methods.balanceOf(state.account.address));
        }
        else
          state.pool.tokenbalance[i] = BigNumber(0);
      }

      result = await state.contracts.multiCall.aggregate(calls);
      for (let i=0; i< length; i++) {
        if (state.account) {
          state.pool.tokenbalance[i] = BigNumber(result[i]);
        }
      }

      //Get NFT staking information
      result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenNFTStaking.methods.getPoolCount()
      ]);

      calls = [];
      length = result[0];

      for (let i=0; i<length; i++) {
        calls.push(state.contracts.tokenNFTStaking.methods.poolInfo(i));
      }

      result = await state.contracts.multiCall.aggregate(calls);
      state.nftStaking.pools = [];
      state.nftStaking.collectionNames = [];
      calls = [];
      for (let i=0; i< length; i++) {
        let nftContract = new window.web3.eth.Contract(abiERC721Enumerable, result[i][0]);
        calls.push(nftContract.methods.name());
      }

      result = await state.contracts.multiCall.aggregate(calls);

      state.nftStaking.collectionNames = result;

      // Get Global other information
      result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenLottery.methods.getTotalWinningPrizeOfLotteries(),
        state.contracts.tokenLottery.methods.getTotalPlayersOfLottery()
      ]);

      state.jumbotron.totalWinnings = BigNumber(result[0]);
      state.jumbotron.totalPlayers = BigNumber(result[1]);
    },
    async read_liquidity(state) {
      var result;
      if (state.account) { 
        if (state.liquidity.coinOne.symbol != "ASTR") {
          let fromTokenContract = new window.web3.eth.Contract(abiERC20, state.liquidity.coinOne.address);

          result = await state.contracts.multiCall.aggregate([
            fromTokenContract.methods.allowance(state.account.address, ADDR_ROUTER),
            fromTokenContract.methods.balanceOf(state.account.address)
          ]);

          if (result[0] > 0)
            state.approvedOneTokenRouter = true;
          else
            state.approvedOneTokenRouter = false;

          state.balance.amountFrom = BigNumber(result[1]);
          state.liquidity.tokenBalance[state.liquidity.coinOne.id] = BigNumber(result[1]);

          if (state.liquidity.coinOne.symbol == "STAR") {
            state.balance.amountSTAR = BigNumber(result[1]);
          }
        }
        else {
          state.approvedOneTokenRouter = true;
          window.web3.eth.getBalance(state.account.address).then(ret=>{
              state.balance.amountFrom = BigNumber(ret);
              state.balance.amountASTR = BigNumber(ret);
              state.liquidity.tokenBalance[0] = BigNumber(ret);
          })
        }

        if (state.liquidity.coinTwo.symbol != "ASTR") {
          let toTokenContract = new window.web3.eth.Contract(abiERC20, state.liquidity.coinTwo.address);

          result = await state.contracts.multiCall.aggregate([
            toTokenContract.methods.balanceOf(state.account.address),
            toTokenContract.methods.allowance(state.account.address, ADDR_ROUTER)
          ]);

          state.balance.amountTo = BigNumber(result[0]);
          state.liquidity.tokenBalance[state.liquidity.coinTwo.id] = BigNumber(result[0]);

          if (state.liquidity.coinTwo.symbol == "STAR")
            state.balance.amountSTAR = BigNumber(result[0]);

          if (result[1] > 0)
            state.approvedTwoTokenRouter = true;
          else
            state.approvedTwoTokenRouter = false;
        }
        else {
          state.approvedTwoTokenRouter = true;
          window.web3.eth.getBalance(state.account.address).then(ret=>{
            state.balance.amountTo = BigNumber(ret);
            state.balance.amountASTR = BigNumber(ret);
            state.liquidity.tokenBalance[0] = BigNumber(ret);
          })
        }
      }
      else {
        state.approvedOneTokenRouter = false;
        state.approvedTwoTokenRouter = false;
        state.balance.amountFrom = BigNumber(0);
        state.balance.amountTo = BigNumber(0);
        state.balance.amountSTAR = BigNumber(0);
        state.balance.amountASTR = BigNumber(0);
      }

      // Get the price of StarSwap token
      if (state.liquidity.tokenInfo[0] != undefined) {
        state.price.ASTR = state.liquidity.tokenInfo[0].market_data.current_price.usd;
      }
      else {
        state.price.ASTR = 0;
      }

      if (state.liquidity.pairInfo[0] != undefined) {
        token_list[2].price = state.liquidity.pairInfo[0].pair.priceUsd;
        state.price.STAR = state.liquidity.pairInfo[0].pair.priceUsd;
      }

      if ((state.liquidity.coinOne.symbol == "ASTR" && state.liquidity.coinTwo.symbol == "WASTR") || 
      (state.liquidity.coinOne.symbol == "WASTR" && state.liquidity.coinTwo.symbol == "ASTR")) {
        state.liquidity.crossOneRouter = false;
        state.liquidity.crossOneTokenSymbol = null;
        state.liquidity.crossOneTokenAddress = null;
        state.liquidity.crossTwoRouter = false;
        state.liquidity.crossTwoTokenSymbol = null;
        state.liquidity.crossTwoTokenAddress = null;
        return;
      }

      // Get pair result
      result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenFactory.methods
        .getPair(state.liquidity.coinOne.address, state.liquidity.coinTwo.address)
      ]);

      if (result[0] == ADDR_NULL) {

        /********* find cross router **************** */
        let length = pool_list.length;
        let crossOneRouter = false;
        let crossTwoRouter = false;
        state.liquidity.crossOneRouter = false;
        state.liquidity.crossTwoRouter = false;
        state.liquidity.crossOneTokenSymbol = null;
        state.liquidity.crossOneTokenAddress = null;
        state.liquidity.crossTwoTokenSymbol = null;
        state.liquidity.crossTwoTokenAddress = null;
        for (let i=0; i<length; i++) {
          let symbol0 = pool_list[i].symbol0;
          let symbol1 = pool_list[i].symbol1;
          let crossAddress;
          let crossSymbol;
          if (symbol0 == state.liquidity.coinOne.symbol || 
            symbol1 == state.liquidity.coinOne.symbol) {
            if (symbol0 == state.liquidity.coinOne.symbol) {
              crossAddress = state.liquidity.tokenMap.get(pool_list[i].symbol1).address;
              crossSymbol = symbol1;
            }
            else if (symbol1 == state.liquidity.coinOne.symbol) {
              crossAddress = state.liquidity.tokenMap.get(pool_list[i].symbol0).address;
              crossSymbol = symbol0;
            }

            result = await state.contracts.multiCall.aggregate([
              state.contracts.tokenFactory.methods
              .getPair(crossAddress, state.liquidity.coinTwo.address)
            ]);
            if (result[0] != ADDR_NULL) {
              state.liquidity.crossOneTokenSymbol = crossSymbol;
              state.liquidity.crossOneTokenAddress = crossAddress;
              state.liquidity.crossOneRouter = true;
              crossOneRouter = true;
              break;
            }
          }
        }

        if (!crossOneRouter) {
          let crossOneAddress;
          let crossOneSymbol;
          let crossTwoAddress;
          let crossTwoSymbol;
          for (let i=0; i<length; i++) {
            let symbol0 = pool_list[i].symbol0;
            let symbol1 = pool_list[i].symbol1;
            if (symbol0 == state.liquidity.coinOne.symbol || 
              symbol1 == state.liquidity.coinOne.symbol) {
              if (symbol0 == state.liquidity.coinOne.symbol) {
                for (let j=0; j<length; j++) {
                  let symbol0_0 = pool_list[j].symbol0;
                  let symbol0_1 = pool_list[j].symbol1;
                  if (symbol0_0 == symbol1 || symbol0_1 == symbol1) {
                    if (symbol0_0 == symbol1) {
                      for (let k=0; k<length; k++) {
                        let symbol1_0 = pool_list[k].symbol0;
                        let symbol1_1 = pool_list[k].symbol1;
                        if (symbol1_0 == symbol0_1 && symbol1_1 == state.liquidity.coinTwo.symbol || 
                          symbol1_1 == symbol0_1 && symbol1_0 == state.liquidity.coinTwo.symbol) {
                          state.liquidity.crossOneTokenSymbol = pool_list[i].symbol1;
                          state.liquidity.crossTwoTokenSymbol = pool_list[j].symbol1;
                          state.liquidity.crossOneTokenAddress = state.liquidity.tokenMap.get(pool_list[i].symbol1).address;
                          state.liquidity.crossTwoTokenAddress = state.liquidity.tokenMap.get(pool_list[j].symbol1).address;
                          state.liquidity.crossTwoRouter = true;
                          crossTwoRouter = true;
                          break;
                        }
                      }
                      if (crossTwoRouter) {
                        break;
                      }
                    }
                    else if (symbol0_1 == symbol1) {
                      for (let k=0; k<length; k++) {
                        let symbol1_0 = pool_list[k].symbol0;
                        let symbol1_1 = pool_list[k].symbol1;
                        if (symbol1_0 == symbol0_0 && symbol1_1 == state.liquidity.coinTwo.symbol || 
                          symbol1_1 == symbol0_0 && symbol1_0 == state.liquidity.coinTwo.symbol) {
                          state.liquidity.crossOneTokenSymbol = pool_list[i].symbol1;
                          state.liquidity.crossTwoTokenSymbol = pool_list[j].symbol0;
                          state.liquidity.crossOneTokenAddress = state.liquidity.tokenMap.get(pool_list[i].symbol1).address;
                          state.liquidity.crossTwoTokenAddress = state.liquidity.tokenMap.get(pool_list[j].symbol0).address;
                          state.liquidity.crossTwoRouter = true;
                          crossTwoRouter = true;
                          break;
                        }
                      }
                      if (crossTwoRouter) {
                        break;
                      }
                    }
                  }
                }
              }
              else if (symbol1 == state.liquidity.coinOne.symbol) {
                for (let j=0; j<length; j++) {
                  let symbol0_0 = pool_list[j].symbol0;
                  let symbol0_1 = pool_list[j].symbol1;
                  if (symbol0_0 == symbol0 || symbol0_1 == symbol0) {
                    if (symbol0_0 == symbol0) {
                      for (let k=0; k<length; k++) {
                        let symbol1_0 = pool_list[k].symbol0;
                        let symbol1_1 = pool_list[k].symbol1;
                        if (symbol1_0 == symbol0_1 && symbol1_1 == state.liquidity.coinTwo.symbol || 
                          symbol1_1 == symbol0_1 && symbol1_0 == state.liquidity.coinTwo.symbol) {
                          state.liquidity.crossOneTokenSymbol = pool_list[i].symbol0;
                          state.liquidity.crossTwoTokenSymbol = pool_list[j].symbol1;
                          state.liquidity.crossOneTokenAddress = state.liquidity.tokenMap.get(pool_list[i].symbol0).address;
                          state.liquidity.crossTwoTokenAddress = state.liquidity.tokenMap.get(pool_list[j].symbol1).address;
                          state.liquidity.crossTwoRouter = true;
                          crossTwoRouter = true;
                          break;
                        }
                      }
                      if (crossTwoRouter) {
                        break;
                      }
                    }
                    else if (symbol0_1 == symbol0) {
                      for (let k=0; k<length; k++) {
                        let symbol1_0 = pool_list[k].symbol0;
                        let symbol1_1 = pool_list[k].symbol1;
                        if (symbol1_0 == symbol0_0 && symbol1_1 == state.liquidity.coinTwo.symbol || 
                          symbol1_1 == symbol0_0 && symbol1_0 == state.liquidity.coinTwo.symbol) {
                          state.liquidity.crossOneTokenSymbol = pool_list[i].symbol0;
                          state.liquidity.crossTwoTokenSymbol = pool_list[j].symbol0;
                          state.liquidity.crossOneTokenAddress = state.liquidity.tokenMap.get(pool_list[i].symbol0).address;
                          state.liquidity.crossTwoTokenAddress = state.liquidity.tokenMap.get(pool_list[j].symbol0).address;
                          state.liquidity.crossTwoRouter = true;
                          crossTwoRouter = true;
                          break;
                        }
                      }
                      if (crossTwoRouter) {
                        break;
                      }
                    }
                  }
                }
              }
            }
            if (crossTwoRouter)
              break;
          }
        }
        /************************* */
        if (crossOneRouter) {
          // First LP
          let path = [];
          path.push(state.liquidity.coinOne.address);
          path.push(state.liquidity.crossOneTokenAddress);
    
          let decimals1 = state.liquidity.coinOne.decimals;
          let unit = BigNumber(1).shiftedBy(decimals1).toString();
  
          result = await state.contracts.multiCall.aggregate([
            state.contracts.tokenRouter.methods.getAmountsOut(unit, path)
          ]);

          // Second LP
          path = [];
          path.push(state.liquidity.crossOneTokenAddress);
          path.push(state.liquidity.coinTwo.address);

          result = await state.contracts.multiCall.aggregate([
            state.contracts.tokenRouter.methods.getAmountsOut(result[0][1], path)
          ]);

          let decimals2 = state.liquidity.coinTwo.decimals * (-1);
          state.liquidity.rateOne2Two = BigNumber(result[0][1]).shiftedBy(decimals2);

          state.liquidity.lpToken = null;
          state.liquidity.amountOne = BigNumber(0);
          state.liquidity.amountTwo = BigNumber(0);
          state.liquidity.totalSupply = BigNumber(0);
          state.liquidity.balance = BigNumber(0);
          state.approvedPairRouter = false;
        }
        else if (crossTwoRouter) {
          // First LP
          let path = [];
          path.push(state.liquidity.coinOne.address);
          path.push(state.liquidity.crossOneTokenAddress);

          let decimals1 = state.liquidity.coinOne.decimals;
          let unit = BigNumber(1).shiftedBy(decimals1).toString();

          result = await state.contracts.multiCall.aggregate([
            state.contracts.tokenRouter.methods.getAmountsOut(unit, path)
          ]);

          // Second LP
          path = [];
          path.push(state.liquidity.crossOneTokenAddress);
          path.push(state.liquidity.crossTwoTokenAddress);

          result = await state.contracts.multiCall.aggregate([
            state.contracts.tokenRouter.methods.getAmountsOut(result[0][1], path)
          ]);

          // third LP
          path = [];
          path.push(state.liquidity.crossTwoTokenAddress);
          path.push(state.liquidity.coinTwo.address);

          result = await state.contracts.multiCall.aggregate([
            state.contracts.tokenRouter.methods.getAmountsOut(result[0][1], path)
          ]);

          let decimals2 = state.liquidity.coinTwo.decimals * (-1);
          state.liquidity.rateOne2Two = BigNumber(result[0][1]).shiftedBy(decimals2);

          state.liquidity.lpToken = null;
          state.liquidity.amountOne = BigNumber(0);
          state.liquidity.amountTwo = BigNumber(0);
          state.liquidity.totalSupply = BigNumber(0);
          state.liquidity.balance = BigNumber(0);
          state.approvedPairRouter = false;
        }
        else {
          state.liquidity.crossOneRouter = false;
          state.liquidity.crossOneTokenSymbol = null;
          state.liquidity.crossOneTokenAddress = null;
          state.liquidity.crossTwoRouter = false;
          state.liquidity.crossTwoTokenSymbol = null;
          state.liquidity.crossTwoTokenAddress = null;
          state.liquidity.lpToken = null;
          state.liquidity.amountOne = BigNumber(0);
          state.liquidity.amountTwo = BigNumber(0);
          state.liquidity.totalSupply = BigNumber(0);
          state.liquidity.balance = BigNumber(0);
          state.approvedPairRouter = false;
          state.liquidity.rateOne2Two = BigNumber(0);
          return;
        }
      }
      else {
        state.liquidity.crossOneRouter = false;
        state.liquidity.crossOneTokenSymbol = null;
        state.liquidity.crossOneTokenAddress = null;
        state.liquidity.crossTwoRouter = false;
        state.liquidity.crossTwoTokenSymbol = null;
        state.liquidity.crossTwoTokenAddress = null;
        state.liquidity.lpToken = result[0];

        let lpContract = new window.web3.eth.Contract(abiPair, result[0]);
        let path = [];
        path.push(state.liquidity.coinOne.address);
        path.push(state.liquidity.coinTwo.address);
  
        let decimals = state.liquidity.coinOne.decimals;
        let unit = BigNumber(1).shiftedBy(decimals).toString();

        result = await state.contracts.multiCall.aggregate([
          lpContract.methods.totalSupply(),
          lpContract.methods.getReserves(),
          state.contracts.tokenRouter.methods.getAmountsOut(unit, path)
        ]);

        state.liquidity.totalSupply = BigNumber(result[0]);
        if (state.liquidity.coinOne.address.toLowerCase() < state.liquidity.coinTwo.address.toLowerCase()) {
          state.liquidity.amountOne = BigNumber(result[1][0]);
          state.liquidity.amountTwo = BigNumber(result[1][1]);
        }
        else {
          state.liquidity.amountOne = BigNumber(result[1][1]);
          state.liquidity.amountTwo = BigNumber(result[1][0]);
        }

        decimals = state.liquidity.coinTwo.decimals * (-1);
        state.liquidity.rateOne2Two = BigNumber(result[2][1]).shiftedBy(decimals);

        if (state.account) {
          result = await state.contracts.multiCall.aggregate([
            lpContract.methods.balanceOf(state.account.address),
            lpContract.methods.allowance(state.account.address, ADDR_ROUTER)
          ]);

          state.liquidity.balance = BigNumber(result[0]);
          if (result[1] > 0) {
            state.approvedPairRouter = true;
          }
          else {
            state.approvedPairRouter = false;
          }
        }
        else {
          state.liquidity.balance = BigNumber(0);
          state.approvedPairRouter = false;
        }
      }
    },
    async read_staking(state) {
      let index = this.state.farming.currentOpenedPoolIndex;
      if (index == null)
        return;

      let poolId = staking_list[index].poolId;
      state.contracts.tokenMaster.methods.poolInfo(poolId)
      .call().then(ret=>{
          state.staking.allocpoint[index] = ret.allocPoint;
          state.staking.totalSupply[index] = BigNumber(ret.totalStakedTokens);
      })

      if (state.account) {
        let tokenContract = new window.web3.eth.Contract(abiERC20, staking_list[index].address);
        let decimal = state.liquidity.tokenMap.get(staking_list[index].symbol).decimals;
        state.staking.tokenDecimals[index] = decimal;

        var result = await state.contracts.multiCall.aggregate([
          state.contracts.tokenMaster.methods.userInfo(poolId, state.account.address),
          state.contracts.tokenMaster.methods.pendingSTAR(poolId,state.account.address),
          tokenContract.methods.balanceOf(state.account.address)
        ]);

        state.staking.balance[index] = BigNumber(result[0][0]);
        state.staking.earnedSTAR[index] = BigNumber(result[0][2]);
        state.staking.pendingSTAR[index] = BigNumber(result[1][0]);
        state.staking.claimableSTAR[index] = BigNumber(result[1][1]);
        state.staking.tokenBalance[index] = BigNumber(result[2]);
      }
      else {
        state.staking.balance[index] = BigNumber(0);
        state.staking.earnedSTAR[index] = BigNumber(0);
        state.staking.pendingSTAR[index] = BigNumber(0);
        state.staking.claimableSTAR[index] = BigNumber(0);
        state.staking.tokenBalance[index] = BigNumber(0);
      }
    },
    async read_masterchef(state) {
      let index = this.state.farming.currentOpenedPoolIndex;
      if (index == null)
        return;

      let poolId = state.liquidity.farm_list[index].poolId;
      let lpContract = new window.web3.eth.Contract(abiPair, state.liquidity.farm_list[index].address);
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenMaster.methods.poolInfo(poolId),
        lpContract.methods.totalSupply()
      ]);

      state.farming.totalSupply[index] = BigNumber(result[0].totalStakedTokens);
      state.farming.allocpoint[index] = result[0].allocPoint;
      state.farming.liquiditybalance[index] = BigNumber(result[1]);

      let tokenInfo = state.liquidity.tokenMap.get(state.liquidity.farm_list[index].symbol0);
      let tokenInfo1 = state.liquidity.tokenMap.get(state.liquidity.farm_list[index].symbol1);
      let decimal0 = state.liquidity.tokenMap.get(state.liquidity.farm_list[index].symbol0).decimals * (-1);
      let decimal1 = state.liquidity.tokenMap.get(state.liquidity.farm_list[index].symbol1).decimals * (-1);

      lpContract.methods.getReserves().call().then((ret)=>{
        if (tokenInfo.address.toLowerCase() < tokenInfo1.address.toLowerCase()) {
          state.liquidity.tvl[index] = BigNumber(ret[0]).shiftedBy(decimal0)
                          .times(tokenInfo.price)
                          .plus(
                          BigNumber(ret[1]).shiftedBy(decimal1)
                          .times(tokenInfo1.price));
        }
        else {
          state.liquidity.tvl[index] = BigNumber(ret[1]).shiftedBy(decimal0)
                          .times(tokenInfo.price)
                          .plus(
                          BigNumber(ret[0]).shiftedBy(decimal1)
                          .times(tokenInfo1.price));
        }
      })

      if (state.account) {
        result = await state.contracts.multiCall.aggregate([
          lpContract.methods.balanceOf(state.account.address),
          state.contracts.tokenMaster.methods.userInfo(poolId, state.account.address),
          state.contracts.tokenMaster.methods.pendingSTAR(poolId,state.account.address)
        ]);

        state.farming.tokenbalance[index] = BigNumber(result[0]);
        state.farming.balance[index] = BigNumber(result[1][0]);
        state.farming.earnedSTAR[index] = BigNumber(result[1][2]);

        state.farming.pendingSTAR[index] = BigNumber(result[2][0]);
        state.farming.claimableSTAR[index] = BigNumber(result[2][1]);
      }
      else {
        state.farming.tokenbalance[index] = BigNumber(0);
        state.farming.balance[index] = BigNumber(0);
        state.farming.earnedSTAR[index] = BigNumber(0);

        state.farming.pendingSTAR[index] = BigNumber(0);
        state.farming.claimableSTAR[index] = BigNumber(0);
      }
    },
    async read_nftStaking(state) {
      const pid = state.nftStaking.currentNFTCollection;
      if (state.account) {
        let addr = state.account.address;
        var result = await state.contracts.multiCall.aggregate([
          state.contracts.tokenNFTStaking.methods.userInfo(pid, addr),
          state.contracts.tokenNFTStaking.methods.getAllRewards(pid, addr),
        ]);

        state.nftStaking.claimedReward = BigNumber(result[0][2]);
        state.nftStaking.claimableReward = BigNumber(result[1]);
        state.nftStaking.userStakedNFT = result[0][0];
      }
      else {
        state.nftStaking.claimedReward = BigNumber(0);
        state.nftStaking.claimableReward = BigNumber(0);
      }

      result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenNFTStaking.methods.poolInfo(pid)
      ]);
      state.nftStaking.poolInfo = result[0];
    },
    async read_starCardsNFT(state) {
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenStarCardsNFT.methods.getSubTokenTracker(),
        state.contracts.tokenStarCardsNFT.methods.totalSupply(),
        state.contracts.tokenStarCardsNFT.methods.getPrice(),
        state.contracts.tokenStarCardsNFT.methods.status()
      ]);

      state.starCardsnft.subTotalSupply = Number(result[0]);
      state.starCardsnft.totalSupply = Number(result[1]);
      state.starCardsnft.price = BigNumber(result[2]);
      state.starCardsnft.status = result[3];
    
      // StarCards Distributor
      if (state.account) {
        let addr = state.account.address;
        result = await state.contracts.multiCall.aggregate([
          state.contracts.tokenStarCardsDistributor.methods.getTotalRewardsOfNFT(addr),
          state.contracts.tokenStarCardsDistributor.methods.withdrawn(addr)
        ]);

        state.starCardsnft.claimableRewards = BigNumber(result[0]);
        state.starCardsnft.claimedRewards = BigNumber(result[1]);
      }
      else {
        state.starCardsnft.claimableRewards = BigNumber(0);
        state.starCardsnft.claimedRewards = BigNumber(0);
      }

      state.starCardsnft.floorPrice = STARCARDS_FLOOR_PRICE;
    },
    async read_starCardsGovernance(state) {
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenDao.methods.numProposals(),
        state.contracts.tokenDao.methods.owner(),
      ]);

      state.starCardsGovernance.proposalCount = result[0];
      state.starCardsGovernance.owner = result[1];
      var calls = [];
      for (let i=0; i<state.starCardsGovernance.proposalCount; i++) {
        calls.push(state.contracts.tokenDao.methods.proposals(i));
      }
  
      state.starCardsGovernance.proposalInfo = await state.contracts.multiCall.aggregate(calls);

      calls = [];
      for (let i=0; i<state.starCardsGovernance.proposalCount; i++) {
        calls.push(state.contracts.tokenDao.methods.status(i));
      }
  
      state.starCardsGovernance.status = await state.contracts.multiCall.aggregate(calls);
    },
    async read_skywalkersNFT(state) {
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenSkywalkersNFT.methods.totalSupply(),
        state.contracts.tokenSkywalkersNFT.methods.getPrice(),
        state.contracts.tokenSkywalkersNFT.methods.status(),
        state.contracts.tokenSkywalkersNFT.methods.max_TokenCountOfAccount()
      ]);

      state.skywalkersnft.totalSupply = Number(result[0]);
      state.skywalkersnft.price = BigNumber(result[1]);
      state.skywalkersnft.status = result[2];
      state.skywalkersnft.maxTokenCountOfAccount = Number(result[3]);

      let status = Number(result[2]);
      if (status == 0)
        state.skywalkersnft.subTotalSupply = 0;
      else if (status == 4)
        state.skywalkersnft.subTotalSupply = state.skywalkersnft.maxSubTokenCount;
      else {
        state.skywalkersnft.subTotalSupply = state.skywalkersnft.totalSupply - 
            state.skywalkersnft.maxSubTokenCount * (status - 1);
      }
    },
    async read_liquiditygenerator(state) {
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenDistributor.methods.totalShares(),
        state.contracts.tokenDistributor.methods.vestingAmount()
      ]);

      state.liquiditygenerator.totalASTRShares = BigNumber(result[0]);
      state.liquiditygenerator.normalVestingSTAR = BigNumber(result[1]);
      state.liquiditygenerator.starPriceInLGE = state.liquiditygenerator.totalASTRShares 
                                    / state.liquiditygenerator.normalVestingSTAR;

      if (state.account) {
        let addr = state.account.address;
        result = await state.contracts.multiCall.aggregate([
          state.contracts.tokenDistributor.methods.recipients(addr),
          state.contracts.tokenDistributor.methods.updateCredit(addr)
        ]);
        state.liquiditygenerator.recipientInfo = result[0];
        state.liquiditygenerator.claimableRewards = BigNumber(result[1]);
      }
      else {
        state.liquiditygenerator.recipientInfo = null;
        state.liquiditygenerator.claimableRewards = BigNumber(0);
      }
    },
    async read_exchanger(state) {
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenExchanger.methods.totalShares(),
        state.contracts.tokenExchanger.methods.price(),
        state.contracts.tokenExchanger.methods.vestingBegin(),
        state.contracts.tokenExchanger.methods.vestingEnd(),
        state.contracts.tokenExchanger.methods.depositEnd(),
        state.contracts.tokenExchanger.methods.remainedSTAR()
      ]);

      state.exchanger.normalVestingSTAR = BigNumber(result[0]);
      state.exchanger.starPriceInLGE = BigNumber(result[1]);
      state.exchanger.vestingBegin = BigNumber(result[2] * 1000);
      state.exchanger.vestingEnd = result[3] * 1000;
      state.exchanger.periodEnd = result[4] * 1000;
      state.exchanger.totalRemainedSTAR = result[5];

      if (state.account) {
        let addr = state.account.address;
        result = await state.contracts.multiCall.aggregate([
          state.contracts.tokenExchanger.methods.recipients(addr),
          state.contracts.tokenExchanger.methods.updateCredit(addr, false),
          state.contracts.tokenExchanger.methods.updateCredit(addr, true)
        ]);

        state.exchanger.recipientInfo = result[0];
        state.exchanger.claimableRewards = BigNumber(result[1]);
        state.exchanger.airdroppableRewards = BigNumber(result[2]);

        result = await state.contracts.multiCall.aggregate([
          state.contracts.tokenExchangerEx.methods.recipientEx(addr),
          // state.contracts.tokenExchangerEx.methods.updateCredit(addr)
        ]);

        state.exchangerEx.totalRewards = BigNumber(state.exchanger.recipientInfo[2]).times(3).div(2);
        state.exchangerEx.claimedRewards = BigNumber(result[0]);
        // state.exchangerEx.claimableRewards = BigNumber(result[1]);

        state.exchangerEx.claimableRewards = (BigNumber(state.exchanger.claimableRewards).times(3).div(2))
                .plus(BigNumber(state.exchanger.recipientInfo[3]).times(3).div(2))
                .minus(BigNumber(state.exchangerEx.claimedRewards));
      }
      else {
        state.exchanger.recipientInfo = null;
        state.exchanger.claimableRewards = BigNumber(0);
        state.exchanger.airdroppableRewards = BigNumber(0);
      }
    },
    async read_lottery(state) {
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenLottery.methods.viewCurrentLotteryId()
      ]);

      state.lottery.currentLotteryId = parseInt(result[0]);

      result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenLottery.methods.viewLottery(result[0])
      ]);

      state.lottery.currentLotteryState = parseInt(result[0].status);
      state.lottery.nextDraw = new Date(result[0].endTime*1000);
      state.lottery.winningPrize = BigNumber(result[0].amountCollectedInSTAR);
      state.lottery.price = BigNumber(result[0].priceTicketInSTAR);

      if(state.account) {
        let addr = state.account.address;
        result = await state.contracts.multiCall.aggregate([
          state.contracts.tokenLottery.methods.getUserLotteryIds(addr),
        ]);

        state.lottery.myResultIds = result[0];
        if(result[0].length) {
          let id = state.lottery.myResultIds[state.lottery.myResultIds.length-state.lottery.myResultIndex-1]
          if (!(id == state.lottery.currentLotteryId && state.lottery.currentLotteryState != 3)) {
            state.contracts.tokenLottery.methods.getBracketsOfMatching(
              id,addr
            ).call().then(ret=>{
              state.lottery.myWinning = ret;
            });
          }

          result = await state.contracts.multiCall.aggregate([
            state.contracts.tokenLottery.methods._userWiningRewardsPerLotteryId(addr,id),
            state.contracts.tokenLottery.methods.viewLottery(id),
            state.contracts.tokenLottery.methods.viewUserInfoForLotteryId(
              addr,id,0,100
            )
          ]);

          state.lottery.winningMine = result[0];
          state.lottery.lastMyResult = result[1];
          if(result[2])
            state.lottery.lastMyNumbers = result[2][1];
        }
      }
      else {
        state.lottery.myWinning = null;
        state.lottery.winningMine = null;
        state.lottery.lastMyResult = null;
        state.lottery.lastMyNumbers = null;
      }

      state.lottery.lastAllResultId = state.lottery.currentLotteryId;
      if(state.lottery.lastAllResultId)
        state.contracts.tokenLottery.methods.viewLottery(state.lottery.lastAllResultId)
        .call().then(ret=>{
          state.lottery.lastAllResult = ret;
        })
    },
    async read_history(state,params) {
      if(params.tab=='all history') {
        if(params.id>0 && params.id<=state.lottery.currentLotteryId) {
          state.contracts.tokenLottery.methods.viewLottery(params.id)
          .call().then(ret=>{
            state.lottery.lastAllResultId = params.id;
            state.lottery.lastAllResult = ret;
          })
        }
      } 
      else if(state.account) {
        if (params.id < 0)
          return;
          
        let addr = state.account.address
        if(state.lottery.myResultIds && params.id<state.lottery.myResultIds.length) {
          let id = state.lottery.myResultIds[state.lottery.myResultIds.length-params.id-1];
          result = await state.contracts.multiCall.aggregate([
            state.contracts.tokenLottery.methods.viewLottery(id)
          ]);
          let result = parseInt(result[0])
          if (result == 3) {
            result = await state.contracts.multiCall.aggregate([
              state.contracts.tokenLottery.methods.getBracketsOfMatching(
                id,addr
              ),
              state.contracts.tokenLottery.methods._userWiningRewardsPerLotteryId(addr,id)
            ]);

            state.lottery.myWinning = result[0];
            state.lottery.winningMine = result[1];
          }
          else {
            state.lottery.myWinning = null;
          }

          result = await state.contracts.multiCall.aggregate([
            state.contracts.tokenLottery.methods.viewLottery(id),
            state.contracts.tokenLottery.methods.viewUserInfoForLotteryId(
              addr,id,0,100
            )
          ]);

          state.lottery.lastMyResult = result[0];
          state.lottery.lastMyNumbers = result[1][1];
          state.lottery.myResultIndex = params.id;
        }
      }
      else {
      state.lottery.myWinning = null;
        state.lottery.winningMine = null;
        state.lottery.myWinning = null;
        state.lottery.lastMyResult = null;
        state.lottery.lastMyNumbers = null;
        state.lottery.myResultIndex = null;
      }
    },
    async read_treasury(state) {
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenTreasury.methods.treasuryBalance(),
        state.contracts.tokenTreasury.methods.calculateRateSTAR2ETH(),
        state.contracts.tokenTreasury.methods.calculateRateLP2ETH(),
        state.contracts.tokenTreasury.methods.calculateBackingRateOfSTAR(),
        state.contracts.tokenTreasury.methods.poolInfo(0),
        state.contracts.tokenTreasury.methods.poolInfo(1),
        state.contracts.tokenTreasury.methods.isEnableBuyback(),
        state.contracts.tokenTreasury.methods.lpToken()
      ]);

      state.treasury.treasuryBalance = BigNumber(result[0]);
      state.treasury.priceSTAR = BigNumber(result[1]);
      state.treasury.priceLP = BigNumber(result[2]);
      state.treasury.bakingPriceSTAR = BigNumber(result[3]);
      state.treasury.remainedSTAR0 = result[4][3];
      state.treasury.totalFund0 = result[4][4];
      state.treasury.remainedSTAR1 = result[5][3];
      state.treasury.totalFund1 = result[5][4];
      state.treasury.isEnableBuyBack = result[6];

      let lpToken = result[7];
      let lpContract = new window.web3.eth.Contract(abiPair, lpToken);

      if (state.account) {
        let addr = state.account.address

        result = await state.contracts.multiCall.aggregate([
          state.contracts.tokenTreasury.methods.pendingSTAR(0, addr),
          state.contracts.tokenTreasury.methods.pendingSTAR(1, addr),
          state.contracts.tokenTreasury.methods.userInfo(addr, 0),
          state.contracts.tokenTreasury.methods.userInfo(addr, 1),
          state.contracts.tokenTreasury.methods.getPurchasedInfo(0, state.account.address),
          state.contracts.tokenTreasury.methods.getPurchasedInfo(1, state.account.address),
          lpContract.methods.balanceOf(state.account.address)
        ]);

        state.treasury.pendingSTAR[0] = BigNumber(result[0]);
        state.treasury.pendingSTAR[1] = BigNumber(result[1]);

        state.treasury.totalPhurchasedSTAR[0] = result[2][1];
        state.treasury.totalEarnedSTAR[0] = result[2][2];
        state.treasury.totalSelledSTAR = BigNumber(result[2][3]);
        state.treasury.totalEarnedASTR = BigNumber(result[2][4]);

        state.treasury.totalPhurchasedSTAR[1] = result[3][1];
        state.treasury.totalEarnedSTAR[1] = result[3][2];

        state.treasury.purchasedInfoByASTR = result[4];
        state.treasury.purchasedInfoByLP = result[5];

        state.treasury.lpBalance = result[6];
      }
    },
  },
  actions: {
    showMessage: ({ commit }, data) => {
      commit(data.kind, data.content);
    },
    hideLoadingWindow({state}) {
      state.global.loadingTransaction = false;
    },
    connect({commit}) {   // Astar mainnet
      const provider = window.ethereum;
      window.web3 = new Web3(window.ethereum);

      provider.request({ 
        method: 'eth_requestAccounts' 
      }).then((accounts) => {
          if(accounts.length==0) {
              console.log("No connected");
          } else {
            window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x250' }],
            }).then((ret) => {
              const account = {
                address: accounts[0],
                //balance: BigNumber(balance,"ether")
              }

              localStorage.setItem("metaMaskAddress", account.address);

              commit('init')
              commit('initProc')
              commit('set_account',account);
              commit('check_approve', account);
              commit('read_globalInfo');
              commit('read_liquidity');
              commit('read_starCardsNFT');
              commit('read_starCardsGovernance');
              commit('read_skywalkersNFT');
              commit('read_liquiditygenerator');
              commit('read_exchanger');
              commit('read_treasury');
              commit('read_lottery');
            }).catch(error => {
              console.log("error:wallet_switchEthereumChain",error)
              if (error.code==4902 || error.code==-32603) {
                window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [{ 
                    chainId: '0x250', 
                    chainName: 'Asar Network',
                    rpcUrls: ['https://evm.astar.network'],
                    blockExplorerUrls: ['https://blockscout.com/astar/'],
                    nativeCurrency: {
                      name: 'Asar',
                      symbol: 'ASTR',
                      decimals: 18
                    }
                  }],
                }).then(() => {
                  const account = {
                    address: accounts[0],
                    //balance: BigNumber(balance,"ether")
                  }
                  localStorage.setItem("metaMaskAddress", account.address);

                  commit('init')
                  commit('initProc')
                  commit('set_account',account);
                  commit('check_approve', account);
                  commit('read_globalInfo');
                  commit('read_liquidity');
                  commit('read_starCardsNFT');
                  commit('read_starCardsGovernance');
                  commit('read_skywalkersNFT');
                  commit('read_liquiditygenerator');
                  commit('read_exchanger');
                  commit('read_treasury');
                  commit('read_lottery');
                }).catch(() => {
                  console.log("error:wallet_switchEthereumChain");
                });
              }
            });
          }
      }).catch((err) => {
        if (err.code === 4001) {
          console.log('Please connect to MetaMask.');
        } else {
          console.error(err);
        }
      });  
    },
    disconnect({state, commit}) {
      localStorage.removeItem("metaMaskAddress");
      state.account = null;

      commit('read_globalInfo');
      commit('read_liquidity');
    },
    addSTARToken() {
      window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: ADDR_STAR,
            symbol: 'STAR',
            decimals: 18,
            //image: tokenImage,
          },
        },
      });
    },
    approvePairRouter({state, commit}) {
      let lpContract = new window.web3.eth.Contract(abiERC20, state.liquidity.lpToken);
      lpContract.methods.approve (
        ADDR_ROUTER,
        UINT256_MAX
      ).send({
        from: state.account.address,
        // gasPrice: state.settings.gasPrice
      })
      .on('transactionHash', function(hash){
        state.global.transactionHash = hash;
        state.global.loadingTransaction = true;
      })
      .on('receipt', function(receipt){
        state.approvedPairRouter = true;
        state.global.loadingTransaction = false;
        commit('show_success', 'Approved Successfully!');
      })
      .on('error', function(error, receipt) { 
        console.log(error);
        state.global.loadingTransaction = false;
      });
    },
    approvePairMaster({state, commit}, params) {
      let lpContract = new window.web3.eth.Contract(abiERC20, params.address);
      lpContract.methods.approve (
        ADDR_MASTER,
        UINT256_MAX
      ).send({
        from: state.account.address,
        // gasPrice: state.settings.gasPrice
      })
      .on('transactionHash', function(hash){
        state.global.transactionHash = hash;
        state.global.loadingTransaction = true;
      })
      .on('receipt', function(receipt){
        state.approvedPairMaster[params.index] = true;
        state.global.loadingTransaction = false;
        commit('show_success', 'Approved Successfully!');
      })
      .on('error', function(error, receipt) { 
        console.log(error);
        state.global.loadingTransaction = false;
      });
    },
    approveStakingPairMaster({state, commit}, params) {
      let lpContract = new window.web3.eth.Contract(abiERC20, params.address);
      lpContract.methods.approve (
        ADDR_MASTER,
        UINT256_MAX
      ).send({
        from: state.account.address,
        // gasPrice: state.settings.gasPrice
      })
      .on('transactionHash', function(hash){
        state.global.transactionHash = hash;
        state.global.loadingTransaction = true;
      })
      .on('receipt', function(receipt){
        state.approvedStakingPairMaster[params.index] = true;
        state.global.loadingTransaction = false;
        commit('show_success', 'Approved Successfully!');
      })
      .on('error', function(error, receipt) { 
        console.log(error);
        state.global.loadingTransaction = false;
      });
    },
    approveRouter({state, commit}, params) {
      let tokenContract = new window.web3.eth.Contract(abiERC20, params.tokenAddress);
      tokenContract.methods.approve (
        ADDR_ROUTER,
        UINT256_MAX
      ).send({
        from: state.account.address,
        // gasPrice: state.settings.gasPrice
      })
      .on('transactionHash', function(hash){
        state.global.transactionHash = hash;
        state.global.loadingTransaction = true;
      })
      .on('receipt', function(receipt){
        if (params.index == 0)
          state.approvedOneTokenRouter = true;
        else
          state.approvedTwoTokenRouter = true;

        state.global.loadingTransaction = false;
        commit('show_success', 'Approved Successfully!');
      })
      .on('error', function(error, receipt) { 
        console.log(error);
        state.global.loadingTransaction = false;
      });
    },
    async approveNFTStaking({state, commit}) {
      const pid = state.nftStaking.currentNFTCollection;
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenNFTStaking.methods.poolInfo(pid)
      ]);

      let nftContract = new window.web3.eth.Contract(abiERC721Enumerable, result[0][0]);
      return new Promise((resolve)=>{
        nftContract.methods.setApprovalForAll (
          ADDR_NFT_STAKING,
          true
        ).send({
          from: state.account.address,
          // gasPrice: state.settings.gasPrice
        })
        .on('transactionHash', function(hash){
          state.global.transactionHash = hash;
          state.global.loadingTransaction = true;
        })
        .on('receipt', function(receipt){
          state.approvedNFTStaking[pid] = true;
          state.global.loadingTransaction = false;
          resolve(receipt);
          commit('show_success', 'Approved Successfully!');
        })
        .on('error', function(error, receipt) { 
          console.log(error);
          state.global.loadingTransaction = false;
        });
      })
    },
    approveLottery({state}) {
      state.contracts.tokenSTAR.methods.approve(
        ADDR_LOTTERY,
        UINT256_MAX
      ).send({
        from: state.account.address,
        // gasPrice: state.settings.gasPrice
      }).then(()=>{
        state.approvedLottery = true;
      })
    },
    async approvePairTreasury({state}) {
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenTreasury.methods.lpToken()
      ]);
      let lpContract = new window.web3.eth.Contract(abiPair, result[0]);
      lpContract.methods.approve(
        ADDR_TREASURY,
        UINT256_MAX
      ).send({
        from: state.account.address,
        // gasPrice: state.settings.gasPrice
      }).then(()=>{
        state.approvedPairTreasury = true;
      })
    },
    approveSTARTreasury({state}) {
      state.contracts.tokenSTAR.methods.approve(
        ADDR_TREASURY,
        UINT256_MAX
      ).send({
        from: state.account.address,
        // gasPrice: state.settings.gasPrice
      }).then(()=>{
        state.approvedSTARTreasury = true;
      })
    },
    async changeTokenProcess({state, commit}, params) {
      state.liquidity.coinOne = params.coinOne;
      state.liquidity.coinTwo = params.coinTwo;
      if (params.decimalChanged) {
        state.balance.amountFrom = BigNumber(0);
        state.balance.amountTo = BigNumber(0);
      }

      commit("read_liquidity");

      return new Promise((resolve,reject)=>{
        resolve();
      })
    },
    add_liquidity({state,commit},params) {
      let deadline = parseInt(new Date().getTime()/1000 + state.settings.deadline*60)
      if (params.tokenOne.symbol == "ASTR") {
        let decimals = params.tokenTwo.decimals;
        state.contracts.tokenRouter.methods.addLiquidityETH (
          params.tokenTwo.address,
          BigNumber(params.amountTwo).shiftedBy(decimals).integerValue().toString(),
          0,
          0,
          state.account.address,
          deadline
        ).send({
          from:state.account.address,
          value:BigNumber(params.amountOne).shiftedBy(18).integerValue().toString(),
          // gasPrice: state.settings.gasPrice
        })
        .on('transactionHash', function(hash){
          state.global.transactionHash = hash;
          state.global.loadingTransaction = true;
        })
        .on('receipt', function(receipt){
          commit('show_success', 'Added liquidity Successfully!');
          commit("read_liquidity");
          state.global.loadingTransaction = false;
        })
        .on('error', function(error, receipt) { 
          console.log(error);
          state.global.loadingTransaction = false;
        });
      }
      else if (params.tokenTwo.symbol == "ASTR") {
        let decimals = params.tokenOne.decimals;
        state.contracts.tokenRouter.methods.addLiquidityETH (
          params.tokenOne.address,
          BigNumber(params.amountOne).shiftedBy(decimals).integerValue().toString(),
          0,
          0,
          state.account.address,
          deadline
        ).send({
          from:state.account.address,
          value:BigNumber(params.amountTwo).shiftedBy(18).integerValue().toString(),
          // gasPrice: state.settings.gasPrice
        })
        .on('transactionHash', function(hash){
          state.global.transactionHash = hash;
          state.global.loadingTransaction = true;
        })
        .on('receipt', function(receipt){
          commit('show_success', 'Added liquidity Successfully!');
          commit("read_liquidity");
          state.global.loadingTransaction = false;
        })
        .on('error', function(error, receipt) { 
          console.log(error);
          state.global.loadingTransaction = false;
        });
      }
      else {
        let decimals0 = params.tokenOne.decimals;
        let decimals1 = params.tokenTwo.decimals;
        state.contracts.tokenRouter.methods.addLiquidity (
          params.tokenOne.address,
          params.tokenTwo.address,
          BigNumber(params.amountOne).shiftedBy(decimals0).integerValue().toString(),
          BigNumber(params.amountTwo).shiftedBy(decimals1).integerValue().toString(),
          0,
          0,
          state.account.address,
          deadline
        ).send({
          from:state.account.address,
          // gasPrice: state.settings.gasPrice
        })
        .on('transactionHash', function(hash){
          state.global.transactionHash = hash;
          state.global.loadingTransaction = true;
        })
        .on('receipt', function(receipt){
          commit('show_success', 'Added liquidity Successfully!');
          commit("read_liquidity");
          state.global.loadingTransaction = false;
        })
        .on('error', function(error, receipt) { 
          console.log(error);
          state.global.loadingTransaction = false;
        });
      }
    },
    removeLiquidity({state, commit},params) {
      let deadline = parseInt(new Date().getTime()/1000+state.settings.deadline*60)
      if (params.tokenOne.symbol == "ASTR") {
        state.contracts.tokenRouter.methods.removeLiquidityETHSupportingFeeOnTransferTokens (
          params.tokenTwo.address,
          BigNumber(params.amount).shiftedBy(18).integerValue().toString(),
          0,
          0,
          state.account.address,
          deadline
        ).send({
          from:state.account.address,
          // gasPrice: state.settings.gasPrice
        })
        .on('transactionHash', function(hash){
          state.global.transactionHash = hash;
          state.global.loadingTransaction = true;
        })
        .on('receipt', function(receipt){
          commit('show_success', 'Removed liquidity Successfully!');
          commit("read_liquidity");
          state.global.loadingTransaction = false;
        })
        .on('error', function(error, receipt) { 
          console.log(error);
          state.global.loadingTransaction = false;
        });
      }
      else if (params.tokenTwo.symbol == "ASTR") {
        state.contracts.tokenRouter.methods.removeLiquidityETHSupportingFeeOnTransferTokens (
          params.tokenOne.address,
          BigNumber(params.amount).shiftedBy(18).integerValue().toString(),
          0,
          0,
          state.account.address,
          deadline
        ).send({
          from:state.account.address,
          // gasPrice: state.settings.gasPrice
        })
        .on('transactionHash', function(hash){
          state.global.transactionHash = hash;
          state.global.loadingTransaction = true;
        })
        .on('receipt', function(receipt){
          commit('show_success', 'Removed liquidity Successfully!');
          commit("read_liquidity");
          state.global.loadingTransaction = false;
        })
        .on('error', function(error, receipt) { 
          console.log(error);
          state.global.loadingTransaction = false;
        });
      }
      else {
        state.contracts.tokenRouter.methods.removeLiquidity (
          params.tokenOne.address,
          params.tokenTwo.address,
          BigNumber(params.amount).shiftedBy(18).integerValue().toString(),
          0,
          0,
          state.account.address,
          deadline
        ).send({
          from:state.account.address,
          // gasPrice: state.settings.gasPrice
        })
        .on('transactionHash', function(hash){
          state.global.transactionHash = hash;
          state.global.loadingTransaction = true;
        })
        .on('receipt', function(receipt){
          commit('show_success', 'Removed liquidity Successfully!');
          commit("read_liquidity");
          state.global.loadingTransaction = false;
        })
        .on('error', function(error, receipt) { 
          console.log(error);
          state.global.loadingTransaction = false;
        });
      }
    },
    getAmountSwap({state},params) {
      let path = []

      path.push(params.coinIn);
      path.push(params.coinOut);

      return new Promise((resolve,reject)=>{
        if(path.length!=2)
          reject('parameter error');
        if(params.baseCoin==1) {
          state.contracts.tokenRouter.methods.getAmountsOut(params.amount,path).call().then(ret=>{
            resolve(ret[1]);
          }).catch((error)=>{
            console.error("tokenRouter.getAmountsOut",error);
          });
        } 
        else {
          state.contracts.tokenRouter.methods.getAmountsIn(params.amount,path).call().then(ret=>{
            resolve(ret[0]);
          }).catch((error)=>{
            console.error("tokenRouter.getAmountsIn",error);
          });
        }
      })
    },
    swap({state,commit},params) {
      if (params.coinA.symbol == "ASTR" && params.coinB.symbol == "WASTR") {
        let wethTokenContract = new window.web3.eth.Contract(abiIWETH, token_list[1].address);

        wethTokenContract.methods.deposit().send({
          from:state.account.address,
          value:BigNumber(params.amountA).shiftedBy(18).integerValue().toString(),
          // gasPrice: state.settings.gasPrice,
        })
        .on('transactionHash', function(hash){
          state.global.transactionHash = hash;
          state.global.loadingTransaction = true;
        })
        .on('receipt', function(receipt){
          commit('show_success', 'Swapped Successfully!');
          commit("read_liquidity");
          state.global.loadingTransaction = false;
        })
        .on('error', function(error, receipt) {
          console.log(error);
          state.global.loadingTransaction = false;
        });

        return;
      }
      else if (params.coinA.symbol == "WASTR" && params.coinB.symbol == "ASTR") {
        let wethTokenContract = new window.web3.eth.Contract(abiIWETH, token_list[1].address);
        wethTokenContract.methods.withdraw(BigNumber(params.amountA).shiftedBy(18).integerValue().toString())
        .send({
          from:state.account.address,
          // gasPrice: state.settings.gasPrice,
        })
        .on('transactionHash', function(hash){
          state.global.transactionHash = hash;
          state.global.loadingTransaction = true;
        })
        .on('receipt', function(receipt){
          commit('show_success', 'Swapped Successfully!');
          commit("read_liquidity");
          state.global.loadingTransaction = false;
        })
        .on('error', function(error, receipt) { 
          console.log(error);
          state.global.loadingTransaction = false;
        });

        return;
      }

      let deadline = parseInt(new Date().getTime()/1000 + state.settings.deadline*60);
      let path = [];
      if (state.liquidity.crossOneRouter) {
        path = [params.coinA.address, state.liquidity.crossOneTokenAddress, params.coinB.address];
      }
      else if (state.liquidity.crossTwoRouter) {
        path = [params.coinA.address, state.liquidity.crossOneTokenAddress, state.liquidity.crossTwoTokenAddress, params.coinB.address];
      }
      else {
        path = [params.coinA.address, params.coinB.address];
      }
      if(params.coinA.symbol=='ASTR') {
        let decimals = params.coinB.decimals;
        state.contracts.tokenRouter.methods.
          swapExactETHForTokensSupportingFeeOnTransferTokens (
            0,
            // BigNumber(params.amountB)
            //       .times(BigNumber(10000).minus(state.settings.slippage)
            //       .div(10000)).shiftedBy(decimals).integerValue().toString(),
            path,
            state.account.address,
            deadline
          ).send({
            from:state.account.address,
            value:BigNumber(params.amountA).shiftedBy(18).integerValue().toString(),
            // gasPrice: state.settings.gasPrice
          })
          .on('transactionHash', function(hash){
            state.global.transactionHash = hash;
            state.global.loadingTransaction = true;
          })
          .on('receipt', function(receipt){
            commit('show_success', 'Swapped Successfully!');
            commit("read_liquidity");
            state.global.loadingTransaction = false;
          })
          .on('error', function(error, receipt) { 
            console.error(error)
          });
      } 
      else if(params.coinB.symbol=='ASTR') {
        let decimals = params.coinA.decimals;
        state.contracts.tokenRouter.methods.
          swapExactTokensForETHSupportingFeeOnTransferTokens (
            BigNumber(params.amountA).shiftedBy(decimals).integerValue().toString(),
            0,
            // BigNumber(params.amountB)
            //       .times(BigNumber(10000).minus(state.settings.slippage)
            //       .div(10000)).shiftedBy(18).integerValue().toString(),
            path,
            state.account.address,
            deadline
          ).send({
            from:state.account.address,
            // gasPrice: state.settings.gasPrice
          })
          .on('transactionHash', function(hash){
            state.global.transactionHash = hash;
            state.global.loadingTransaction = true;
          })
          .on('receipt', function(receipt){
            commit('show_success', 'Swapped Successfully!');
            commit("read_liquidity");
            state.global.loadingTransaction = false;
          })
          .on('error', function(error, receipt) { 
            console.log(error)
          });
      }
      else {
        let decimals0 = params.coinA.decimals;
        let decimals1 = params.coinB.decimals;

        state.contracts.tokenRouter.methods.
          swapExactTokensForTokensSupportingFeeOnTransferTokens (
            BigNumber(params.amountA).shiftedBy(decimals0).integerValue().toString(),
            0,
            path,
            state.account.address,
            deadline
          ).send({
            from:state.account.address,
            // gasPrice: state.settings.gasPrice
          })
          .on('transactionHash', function(hash){
            state.global.transactionHash = hash;
            state.global.loadingTransaction = true;
          })
          .on('receipt', function(receipt){
            commit('show_success', 'Swapped Successfully!');
            state.global.loadingTransaction = false;
            commit("read_liquidity");
          })
          .on('error', function(error, receipt) { 
            console.log(error)
          });
      }
    },
    deposit({state,commit/*,dispatch*/},params) {
      if (params.amount == null) {
        commit('show_warning','Wrong token amount.');
        return;
      }

      let decimals = params.decimals;
      state.farming.currentOpenedPoolIndex = params.currentOpenedPoolIndex;
      state.contracts.tokenMaster.methods.deposit(
        params.index,
        BigNumber(params.amount).shiftedBy(decimals).integerValue().toString()
      ).send({
        from: state.account.address,
        // gasPrice: state.settings.gasPrice
      })
      .on('transactionHash', function(hash){
        state.global.transactionHash = hash;
        state.global.loadingTransaction = true;
      })
      .on('receipt', function(receipt){
        commit('show_success', 'Deposited Successfully!');
        if (params.staking)
          commit("read_staking");
        else
          commit("read_masterchef");
        state.global.loadingTransaction = false;
      })
      .on('error', function(error, receipt) { 
        console.log(error);
        state.global.loadingTransaction = false;
      });
    },
    withdraw({state,commit},params) {
      if (params.amount == null) {
        commit('show_success','Wrong token amount.');
        return;
      }

      state.contracts.tokenMaster.methods.availableForWithdraw(
        state.account.address, params.index
      ).call().then(ret=>{
        if(ret > 0) {
          let decimals = params.decimals;
          state.farming.currentOpenedPoolIndex = params.currentOpenedPoolIndex;
          state.contracts.tokenMaster.methods.withdraw(
            params.index,
            BigNumber(params.amount).shiftedBy(decimals).integerValue().toString()
          ).send({
            from: state.account.address,
            // gasPrice: state.settings.gasPrice
          })
          .on('transactionHash', function(hash){
            state.global.transactionHash = hash;
            state.global.loadingTransaction = true;
          })
          .on('receipt', function(receipt){
            commit('show_success', 'Withdrawed Successfully!');
            if (params.staking)
              commit("read_staking");
            else
              commit("read_masterchef");
            state.global.loadingTransaction = false;
          })
          .on('error', function(error, receipt) { 
            console.log(error);
            state.global.loadingTransaction = false;
          });

        } else {
          commit('show_warning','No withdrawable Tokens.');
        }
      })
    },
    harvest({state,commit},params) {
      return new Promise((resolve)=>{
        state.contracts.tokenMaster.methods.canHarvest(
          params.pid, state.account.address
        ).call().then(ret=>{
          if(ret) {
            this.state.farming.currentOpenedPoolIndex = params.currentOpenedPoolIndex;
            state.contracts.tokenMaster.methods.harvestForDeposit(params.pid, params.depositIndex)
            .send({
                from: state.account.address,
                // gasPrice: state.settings.gasPrice
            })
            .on('transactionHash', function(hash){
              state.global.transactionHash = hash;
              state.global.loadingTransaction = true;
            })
            .on('receipt', function(receipt){
              commit('show_success', 'Harvested Successfully!');
              if (params.staking)
                  commit("read_staking");
                else
                  commit("read_masterchef");
              resolve(receipt);
              state.global.loadingTransaction = false;
            })
            .on('error', function(error, receipt) { 
              console.log(error);
              state.global.loadingTransaction = false;
            });
          } else {
            commit('show_warning','Cannot harvest! Still locked.');
            resolve(ret);
          }
        })
      })
    },
    async getDepositExtraInfoInFarming ({state},pid) {       
      state.global.transactionHash = "Getting Harvest Information.";
      state.global.loadingTransaction = true;
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenMaster.methods.getDepositInfo(pid, state.account.address),
        state.contracts.tokenMaster.methods.getTaxInfo(pid, state.account.address),
        state.contracts.tokenMaster.methods.getEarnedTokenInfo(pid, state.account.address)
      ]);

      return new Promise((resolve)=>{
        state.farming.depositInfo = result[0];
        state.farming.taxInfo = result[1];
        state.farming.earnedTokenInfo = result[2][0];
        state.farming.claimableTokenInfo = result[2][1];
        state.global.loadingTransaction = false;

        resolve(result);
      });
    },
    async getDepositExtraInfoInStaking ({state},pid) {       
      state.global.transactionHash = "Getting Harvest Information.";
      state.global.loadingTransaction = true;
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenMaster.methods.getDepositInfo(pid, state.account.address),
        state.contracts.tokenMaster.methods.getTaxInfo(pid, state.account.address),
        state.contracts.tokenMaster.methods.getEarnedTokenInfo(pid, state.account.address)
      ]);

      return new Promise((resolve)=>{
        state.staking.depositInfo = result[0];
        state.staking.taxInfo = result[1];
        state.staking.earnedTokenInfo = result[2][0];
        state.staking.claimableTokenInfo = result[2][1];
        state.global.loadingTransaction = false;

        resolve(result);
      });
    },
    mint({state,commit},params) {      
      if (state.account==null) {
        commit('show_warning', 'Please connect Wallet!');
        return;         
      }

      if (state.starCardsnft.subTotalSupply == state.starCardsnft.maxSubTokenCount) {
        commit('show_warning', 'No More Tokens!');
        return;
      }

      if (Number(state.starCardsnft.subTotalSupply) + Number(params) > state.starCardsnft.maxSubTokenCount) {
        commit('show_warning', 'No More Tokens!');
        return;
      }

      state.contracts.tokenStarCardsNFT.methods.status().call().then((ret)=>{
        state.starCardsnft.status = ret;
        let status = ret;
        if (Number(ret) == 0) {         // Pending status
          commit('show_warning', 'Mint Not Started Yet!');
          return;
        }
        else if (Number(ret) == 4)  {   // End status
          commit('show_warning', 'Mint Already Ended!');
          return;
        }
        else {
          state.contracts.tokenStarCardsNFT.methods.enableMint()
          .call().then((ret)=>{
            if (!ret) {
              commit('show_warning', 'Minting is disabled!');
              return;
            }
            else {
              state.contracts.tokenStarCardsNFT.methods.getTokenCount(state.account.address, status)
              .call().then((ret)=>{
                if (Number(ret) + Number(params) > state.starCardsnft.maxTokenCountOfAccount) {
                  commit('show_warning', 'Too Many Tokens At Your Wallet!');
                  return;
                }

                state.contracts.tokenStarCardsNFT.methods.isReservePeriod()
                .call().then((ret)=>{
                  if (ret) {
                    const leaves = whiteList.map((addr) => keccak256(addr));
                    const tree = new MerkleTree(leaves, keccak256, { sort: true });
                    const root = tree.getRoot().toString("hex");
              
                    console.log("root_hexroot : ", tree.getHexRoot())
              
                    const leaf = keccak256(state.account.address);
                    let proof = tree.getHexProof(leaf);

                    if (!tree.verify(proof, leaf, root)) {
                      commit('show_warning', 'Not Reserved Account!');
                      return;
                    }
                    state.contracts.tokenStarCardsNFT.methods.presaleMint(params, proof).send({
                      from: state.account.address,
                      value:BigNumber(state.starCardsnft.price).times(params).integerValue().toString(),
                      // gasPrice: state.settings.gasPrice
                    })
                    .on('transactionHash', function(hash){
                      state.global.transactionHash = hash;
                      state.global.loadingTransaction = true;
                    })
                    .on('receipt', function(receipt){
                      commit('show_success', 'Minted NFT Successfully!');
                      commit('read_starCardsNFT');
                      state.global.loadingTransaction = false;
                    })
                    .on('error', function(error, receipt) { 
                      console.log(error);
                      state.global.loadingTransaction = false;
                    });
                  }
                  else {
                    state.contracts.tokenStarCardsNFT.methods.pubsaleMint(params).send({
                      from: state.account.address,
                      value:BigNumber(state.starCardsnft.price).times(params).integerValue().toString(),
                      // gasPrice: state.settings.gasPrice
                    })
                    .on('transactionHash', function(hash){
                      state.global.transactionHash = hash;
                      state.global.loadingTransaction = true;
                    })
                    .on('receipt', function(receipt){
                      commit('show_success', 'Minted NFT Successfully!');
                      commit('read_starCardsNFT');
                      state.global.loadingTransaction = false;
                    })
                    .on('error', function(error, receipt) { 
                      console.log(error);
                      state.global.loadingTransaction = false;
                    });
                  }
                })
              }).catch((error)=>{
                console.error("getTokenCount()",error)
              });
            }
          })
        }
      }).catch((error)=>{
        console.error("tokenStarCardsNFT.getStatus",error)
      });
    },
    async getTokenIdsOfOwner({state, commit},params) {
      if (state.account==null) {
          commit('show_warning', 'Please connect Wallet!');
          return false;         
      }

      if (!window.web3.utils.isAddress(params.account)) {
          commit('show_warning', 'Address Error!');
          return false;
      }

      state.starCardsnft.searchResult = false;
      state.global.loadingNFTs = true;

      await state.contracts.tokenStarCardsNFT.methods.getTokenIdsOfOwner(params.account)
        .call().then((ret)=>{
          state.starCardsnft.tokenIds = ret[0];
          state.starCardsnft.tokenPrices = ret[1];
          state.starCardsnft.tokenURIs = ret[2];
      })

      return true;
    },
    async constructTokenInfo({state,commit}) {
        let nlength = state.starCardsnft.tokenURIs.length;

        if (nlength == 0) {
            state.starCardsnft.searchResult = false;
            state.global.loadingNFTs = false;
            commit('show_warning', 'No Result!');

            return;
        }

        let imageURLs = [];
        let tokenNames = [];
        let tokenAttributes = [];

        for(let i = 0;i<nlength;i++) {
            await axios.get(state.starCardsnft.tokenURIs[i], {
                }).then(x => {
                    imageURLs.push(x.data.image);
                    tokenNames.push(x.data.name);
                    tokenAttributes.push(x.data.attributes);
                })
        }

        state.starCardsnft.imageURIs = imageURLs;
        state.starCardsnft.tokenNames = tokenNames;
        state.starCardsnft.tokenAttributes = tokenAttributes;
        
        state.starCardsnft.searchResult = true;
        state.global.loadingNFTs = false;
    },
    async transferToken({state,commit}, params) {
      if (!window.web3.utils.isAddress(params.to)) {
          commit('show_warning', 'Address Error!');
          return false;
      }

      if (params.from == params.to) {
          commit('show_warning', 'Recipient Address can not be the same as the Spender Address!');
          return false;
      }

      state.contracts.tokenStarCardsNFT.methods.ownerOf(params.tokenID).call().then((ret)=>{
        if (String(ret).toLowerCase() != String(state.account.address).toLowerCase()) {
          commit('show_warning', 'You are not the owner of this token!');
          return false;           
        }
        else {
          state.contracts.tokenStarCardsNFT.methods.safeTransferFrom(params.from, params.to, params.tokenID)
          .send({
              from: state.account.address,
              // gasPrice: state.settings.gasPrice
          })
          .on('transactionHash', function(hash){
            state.global.transactionHash = hash;
            state.global.loadingTransaction = true;
          })
          .on('receipt', function(receipt){
            state.global.loadingTransaction = false;
            commit('show_success', 'Transfered Successfully!');

            state.contracts.tokenStarCardsNFT.methods.getTokenIdsOfOwner(params.from).call({
              from: state.account.address
            }).then((ret)=>{
                state.starCardsnft.tokenIds = ret[0];
                state.starCardsnft.tokenPrices = ret[1];
                state.starCardsnft.tokenURIs = ret[2];
            })
          })
          .on('error', function(error, receipt) { 
            console.log(error);
            state.global.loadingTransaction = false;
          });
        }
      }).catch((error)=>{
        console.error("StarCardsNFT.ownerOf",error)
      });
    },
    async getUnStakedTokenIdsOfOwner({state, commit},params) {
      if (state.account==null) {
          commit('show_warning', 'Please connect Wallet!');
          return false;         
      }
  
      if (!window.web3.utils.isAddress(params.account)) {
          commit('show_warning', 'Address Error!');
          return false;
      }

      state.global.loadingNFTs = true;
      state.nftStaking.searchUnStakedResult = false;

      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenNFTStaking.methods.poolInfo(state.nftStaking.currentNFTCollection)
      ]);

      var nftCollection = new window.web3.eth.Contract(abiERC721Enumerable, result[0][0]);
  
      state.nftStaking.unStakedtokenIds = [];
      await nftCollection.methods.balanceOf(params.account)
        .call().then(async (ret)=>{
          if (ret > 0) {
            var calls = [];
            for (let i=0; i<ret; i++) {
              calls.push(nftCollection.methods.tokenOfOwnerByIndex(params.account, i));
            }
            var result = await state.contracts.multiCall.aggregate(calls);
            for (let j=0; j<ret; j++) {
              state.nftStaking.unStakedtokenIds.push(result[j])
            }

            calls = [];
            var tokenIds = state.nftStaking.unStakedtokenIds;
            let length = tokenIds.length;
            for (let i=0; i<length; i++) {
              calls.push(nftCollection.methods.tokenURI(tokenIds[i]));
            }

            result = await state.contracts.multiCall.aggregate(calls);
            state.nftStaking.unStakedtokenURIs = result;
          }
      })
  
      return true;
    },
    async constructUnStakedTokenInfo({state,commit}) {
      let nlength = state.nftStaking.unStakedtokenURIs.length;

      if (nlength == 0) {
          state.nftStaking.searchUnStakedResult = false;
          state.global.loadingNFTs = false;
          commit('show_warning', 'You have no NFT items!');
  
          return;
      }
  
      let imageURLs = [];
      let tokenNames = [];
      let tokenAttributes = [];
  
      for(let i = 0;i<nlength;i++) {
          await axios.get(state.nftStaking.unStakedtokenURIs[i], {
              }).then(x => {
                  imageURLs.push(x.data.image);
                  tokenNames.push(x.data.name);
                  tokenAttributes.push(x.data.attributes);
              })
      }
  
      state.nftStaking.unStakedimageURIs = imageURLs;
      state.nftStaking.unStakedtokenNames = tokenNames;
      state.nftStaking.unStakedtokenAttributes = tokenAttributes;
      
      state.nftStaking.searchUnStakedResult = true;
      state.global.loadingNFTs = false;
    },
    async getStakedTokenIdsOfOwner({state, commit},params) {
      const pid = state.nftStaking.currentNFTCollection;
      if (state.account==null) {
          commit('show_warning', 'Please connect Wallet!');
          return false;         
      }
  
      if (!window.web3.utils.isAddress(params.account)) {
          commit('show_warning', 'Address Error!');
          return false;
      }

      state.nftStaking.searchStakedResult = false;
      state.global.loadingNFTs = true;
  
      await state.contracts.tokenNFTStaking.methods.activeTokenIdsOf(pid, params.account)
        .call().then((ret)=>{
          state.nftStaking.stakedtokenIds = ret[0];
          state.nftStaking.stakedtokenURIs = ret[1];
      })
  
      return true;
    },
    async constructStakedTokenInfo({state,commit}) {
      let nlength = state.nftStaking.stakedtokenURIs.length;
  
      if (nlength == 0) {
          state.nftStaking.searchStakedResult = false;
          state.global.loadingNFTs = false;
          commit('show_warning', 'You have no staked NFT items!');
  
          return;
      }
  
      let imageURLs = [];
      let tokenNames = [];
      let tokenAttributes = [];
  
      for(let i = 0;i<nlength;i++) {
          await axios.get(state.nftStaking.stakedtokenURIs[i], {
              }).then(x => {
                  imageURLs.push(x.data.image);
                  tokenNames.push(x.data.name);
                  tokenAttributes.push(x.data.attributes);
              })
      }
  
      state.nftStaking.stakedimageURIs = imageURLs;
      state.nftStaking.stakedtokenNames = tokenNames;
      state.nftStaking.stakedtokenAttributes = tokenAttributes;
      
      state.nftStaking.searchStakedResult = true;
      state.global.loadingNFTs = false;
    },
    selectCollection({state,commit}, params) {
      state.nftStaking.currentNFTCollection = params;

      state.nftStaking.stakedtokenIds = [];
      state.nftStaking.stakedtokenURIs = [];
      state.nftStaking.stakedimageURIs = [];
      state.nftStaking.stakedtokenNames = [];
      state.nftStaking.stakedtokenAttributes = [];

      state.nftStaking.unStakedtokenIds = [];
      state.nftStaking.unStakedtokenURIs = [];
      state.nftStaking.unStakedimageURIs = [];
      state.nftStaking.unStakedtokenNames = [];
      state.nftStaking.unStakedtokenAttributes = [];

      commit('read_nftStaking');
    },
    async stakeNFT({state,commit}, params) {
      const pid = state.nftStaking.currentNFTCollection;
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenNFTStaking.methods.poolInfo(pid),
        state.contracts.tokenNFTStaking.methods.isEligible(state.account.address),
      ]);

      if (result[0][1]) {
        if (result[1]) {
          let tokenIds = [];
          tokenIds.push(params.tokenId);
          return new Promise((resolve)=>{
            state.contracts.tokenNFTStaking.methods.deposit(pid, tokenIds)
            .send({
                from: state.account.address,
                // gasPrice: state.settings.gasPrice,
            })
            .on('transactionHash', function(hash){
              state.global.transactionHash = hash;
              state.global.loadingTransaction = true;
            })
            .on('receipt', async function(receipt){
              commit('show_success', 'Staked Successfully!');
              commit('read_nftStaking');
              state.global.loadingTransaction = false;
              resolve(receipt);
            })
            .on('error', function(error, receipt) { 
              console.log(error);
              state.global.loadingTransaction = false;
            });
          })
        }
        else {
          commit('show_warning', "Insufficient eligible token amount!");
        }
      }
      else {
        commit('show_warning', "StarCards staking is not enabled now!");
      }
    },
    async UnStakeNFT({state,commit}, params) {
      const pid = state.nftStaking.currentNFTCollection;

      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenNFTStaking.methods.poolInfo(pid),
        state.contracts.tokenNFTStaking.methods.isEligible(state.account.address),
        state.contracts.tokenNFTStaking.methods.canHarvest(pid, state.account.address)
      ]);

      if (result[0][1]) {
        if (result[1]) {
          if (result[2]) {
            let tokenIds = [];
            tokenIds.push(params.tokenId);
            return new Promise((resolve)=>{
              state.contracts.tokenNFTStaking.methods.withdraw(pid, tokenIds)
              .send({
                  from: state.account.address,
                  // gasPrice: state.settings.gasPrice,
              })
              .on('transactionHash', function(hash){
                state.global.transactionHash = hash;
                state.global.loadingTransaction = true;
              })
              .on('receipt', function(receipt){
                commit('show_success', 'Unstaked Successfully!');
                commit('read_nftStaking');
                state.global.loadingTransaction = false;
                resolve(receipt);
              })
              .on('error', function(error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                console.log(error);
                state.global.loadingTransaction = false;
              });
            })
          }
          else {
            commit('show_warning', "You can not unstake yet!");
          }
        }
        else {
          commit('show_warning', "Insufficient eligible token amount!");
        }
      }
      else {
        commit('show_warning', "StarCards staking is not enabled now!");
      }
    },
    async claimRewardsForNFTStaking({state,commit}) {
      const pid = state.nftStaking.currentNFTCollection;

      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenNFTStaking.methods.getAllRewards(pid, state.account.address),
        state.contracts.tokenNFTStaking.methods.isEligible(state.account.address),
        state.contracts.tokenNFTStaking.methods.canHarvest(pid, state.account.address)
      ]);

      if (result[0] > 0) {
        if (result[1]) {
          if (result[2]) {
            state.contracts.tokenNFTStaking.methods.claimAll(pid)
              .send({
                  from: state.account.address,
                  // gasPrice: state.settings.gasPrice
              })
              .on('transactionHash', function(hash){
                state.global.transactionHash = hash;
                state.global.loadingTransaction = true;
              })
              .on('receipt', function(receipt){
                commit('show_success', 'Claimed Successfully!');
                commit('read_nftStaking');
                state.global.loadingTransaction = false;
              })
              .on('error', function(error, receipt) { 
                console.log(error);
                state.global.loadingTransaction = false;
              });
          }
          else {
            commit('show_warning', "You can not claim reward yet!");
          }
        }
        else {
          commit('show_warning', "Insufficient eligible token amount!");
        }
      }
      else {
        commit('show_warning', "StarCards staking is not enabled now!");
      }
    },
    claimForStarCardsDistributor({state,commit}) {
      state.contracts.tokenStarCardsDistributor.methods.getTotalRewardsOfNFT(state.account.address)
      .call().then((ret)=>{
          if (ret > 0) {
            state.contracts.tokenStarCardsDistributor.methods.claim()
            .send({
                from: state.account.address,
                // gasPrice: state.settings.gasPrice,
            })
            .on('transactionHash', function(hash){
              state.global.transactionHash = hash;
              state.global.loadingTransaction = true;
            })
            .on('receipt', function(receipt){
              commit('show_success', 'Claimed Successfully!');
              commit('read_starCardsNFT');
              state.global.loadingTransaction = false;
            })
            .on('error', function(error, receipt) { 
              console.log(error);
              state.global.loadingTransaction = false;
            });
          }
          else {
            commit('show_warning', 'There is no reward!');
          }
      })
    },
    mintSkywalkers({state,commit},params) {      
      if (state.account==null) {
        commit('show_warning', 'Please Connect Wallet!');
        return;         
      }

      if (state.skywalkersnft.subTotalSupply == state.skywalkersnft.maxSubTokenCount) {
        commit('show_warning', 'No More NFTs Available!');
        return;
      }

      if (Number(state.skywalkersnft.subTotalSupply) + Number(params) > state.skywalkersnft.maxSubTokenCount) {
        commit('show_warning', 'No More NFTs Available!');
        return;
      }

      state.contracts.tokenSkywalkersNFT.methods.status().call().then((ret)=>{
        state.skywalkersnft.status = ret;
        let status = ret;
        if (Number(ret) == 0) {         // Pending status
          commit('show_warning', 'Minting has not started!');
          return;
        }
        else if (Number(ret) == 4)  {   // End status
          commit('show_warning', 'Minting has ended!');
          return;
        }
        else {
          state.contracts.tokenSkywalkersNFT.methods.enableMint()
          .call().then((ret)=>{
            if (!ret) {
              commit('show_warning', 'Minting is disabled Ser!');
              return;
            }
            else {
              state.contracts.tokenSkywalkersNFT.methods.getTokenCount(state.account.address, status)
              .call().then((ret)=>{
                if (Number(ret) + Number(params) > state.skywalkersnft.maxTokenCountOfAccount) {
                  commit('show_warning', 'You can not mint that many NFTs Ser!');
                  return;
                }

                state.contracts.tokenSkywalkersNFT.methods.isReservePeriod().call().then((ret)=>{
                  if (ret) {
                    const leaves = whiteList1.map((addr) => keccak256(addr));
                    const tree = new MerkleTree(leaves, keccak256, { sort: true });
                    const root = tree.getRoot().toString("hex");
              
                    console.log("root_hexroot : ", tree.getHexRoot())
              
                    const leaf = keccak256(state.account.address);
                    let proof = tree.getHexProof(leaf);

                    if (!tree.verify(proof, leaf, root)) {
                      commit('show_warning', 'You are not whitelisted Ser!');
                      return;
                    }
        
                    state.contracts.tokenSkywalkersNFT.methods.presaleMint(params, proof).send({
                      from: state.account.address,
                      value:BigNumber(state.skywalkersnft.price).times(params).integerValue().toString(),
                      // gasPrice: state.settings.gasPrice
                    })
                    .on('transactionHash', function(hash){
                      state.global.transactionHash = hash;
                      state.global.loadingTransaction = true;
                    })
                    .on('receipt', function(receipt){
                      commit('show_success', 'Minted Successfully!');
                      commit('read_skywalkersNFT');
                      state.global.loadingTransaction = false;
                    })
                    .on('error', function(error, receipt) { 
                      console.log(error);
                      state.global.loadingTransaction = false;
                    });
                  }
                  else {
                    state.contracts.tokenSkywalkersNFT.methods.pubsaleMint(params).send({
                      from: state.account.address,
                      value:BigNumber(state.skywalkersnft.price).times(params).integerValue().toString(),
                      // gasPrice: state.settings.gasPrice
                    })
                    .on('transactionHash', function(hash){
                      state.global.transactionHash = hash;
                      state.global.loadingTransaction = true;
                    })
                    .on('receipt', function(receipt){
                      commit('show_success', 'Minted Successfully!');
                      commit('read_skywalkersNFT');
                      state.global.loadingTransaction = false;
                    })
                    .on('error', function(error, receipt) { 
                      console.log(error);
                      state.global.loadingTransaction = false;
                    });
                  }
                })
              }).catch((error)=>{
                console.error("getTokenCount()",error)
              });
            }
          })
        }
      }).catch((error)=>{
        console.error("tokenSkywalkersNFT.getStatus",error)
      });
    },
    async getTokenIdsOfOwnerInSkywalkers({state, commit},params) {
      if (state.account==null) {
          commit('show_warning', 'Please Connect Wallet');
          return false;         
      }

      if (!window.web3.utils.isAddress(params.account)) {
          commit('show_warning', 'Address Error!');
          return false;
      }

      state.skywalkersnft.searchResult = false;
      state.global.loadingNFTs = true;

      await state.contracts.tokenSkywalkersNFT.methods.getTokenIdsOfOwner(params.account)
        .call().then((ret)=>{
          state.skywalkersnft.tokenIds = ret[0];
          state.skywalkersnft.tokenPrices = ret[1];
          state.skywalkersnft.tokenURIs = ret[2];
      })

      return true;
    },
    async constructTokenInfoInSkywalkers({state,commit}) {
        let nlength = state.skywalkersnft.tokenURIs.length;

        if (nlength == 0) {
            state.skywalkersnft.searchResult = false;
            state.global.loadingNFTs = false;
            commit('show_warning', 'No Result!');

            return;
        }

        let imageURLs = [];
        let tokenNames = [];
        let tokenAttributes = [];

        for(let i = 0;i<nlength;i++) {
            await axios.get(state.skywalkersnft.tokenURIs[i], {
                }).then(x => {
                    imageURLs.push(x.data.image);
                    tokenNames.push(x.data.name);
                    tokenAttributes.push(x.data.attributes);
                })
        }

        state.skywalkersnft.imageURIs = imageURLs;
        state.skywalkersnft.tokenNames = tokenNames;
        state.skywalkersnft.tokenAttributes = tokenAttributes;
        
        state.skywalkersnft.searchResult = true;
        state.global.loadingNFTs = false;
    },
    async transferTokenInSkywalkers({state,commit}, params) {
      if (!window.web3.utils.isAddress(params.to)) {
          commit('show_warning', 'Address Error!');
          return false;
      }

      if (params.from == params.to) {
          commit('show_warning', 'Recipient Address can not be the same as the Spender Address!');
          return false;
      }

      state.contracts.tokenSkywalkersNFT.methods.ownerOf(params.tokenID).call().then((ret)=>{
        if (String(ret).toLowerCase() != String(state.account.address).toLowerCase()) {
          commit('show_warning', 'You are not the owner of this token!');
          return false;           
        }
        else {
          state.contracts.tokenSkywalkersNFT.methods.safeTransferFrom(params.from, params.to, params.tokenID)
          .send({
              from: state.account.address,
              // gasPrice: state.settings.gasPrice
          })
          .on('transactionHash', function(hash){
            state.global.transactionHash = hash;
            state.global.loadingTransaction = true;
          })
          .on('receipt', function(receipt){
            state.global.loadingTransaction = false;
            commit('show_success', 'Transfered Successfully!');

            state.contracts.tokenSkywalkersNFT.methods.getTokenIdsOfOwner(params.from).call({
              from: state.account.address
            }).then((ret)=>{
                state.skywalkersnft.tokenIds = ret[0];
                state.skywalkersnft.tokenPrices = ret[1];
                state.skywalkersnft.tokenURIs = ret[2];
            })
          })
          .on('error', function(error, receipt) { 
            console.log(error);
            state.global.loadingTransaction = false;
          });
        }
      }).catch((error)=>{
        console.error("SkywalkersNFT.ownerOf",error)
      });
    },
    claimSTAR({state,commit}) {
      state.contracts.tokenDistributor.methods.calculateCredit(
        state.account.address
      ).call().then(ret=>{
        if(ret > 0) {
          state.contracts.tokenDistributor.methods.claim().send({
              from: state.account.address,
              // gasPrice: state.settings.gasPrice
          })
          .on('transactionHash', function(hash){
            state.global.transactionHash = hash;
            state.global.loadingTransaction = true;
          })
          .on('receipt', function(receipt){
            commit('show_success', 'Success!');
            commit('read_liquiditygenerator');
            state.global.loadingTransaction = false;
          })
          .on('error', function(error, receipt) { 
            console.log(error);
            state.global.loadingTransaction = false;
          });
        } else {
          commit('show_warning','Cannot Claim! Current Balance is Zero.');
        }
      })
    },
    depositASTR({state,commit}, params) {
      state.contracts.tokenLiquidityGenerator.methods.deposit(
      ).send({
        from: state.account.address,
        value:BigNumber(params.amount).shiftedBy(18).integerValue().toString(),
        // gasPrice: state.settings.gasPrice
      })
      .on('transactionHash', function(hash){
        state.global.transactionHash = hash;
        state.global.loadingTransaction = true;
      })
      .on('receipt', function(receipt){
        commit('show_success', 'Success!');
        commit('read_liquiditygenerator');
        state.global.loadingTransaction = false;
      })
      .on('error', function(error, receipt) { 
        console.log(error);
        state.global.loadingTransaction = false;
      });
    },
    depositASTRInExchanger({state,commit}, params) {
      state.contracts.tokenExchanger.methods.deposit(
      ).send({
        from: state.account.address,
        value:BigNumber(params.amount).shiftedBy(18).integerValue().toString(),
        // gasPrice: state.settings.gasPrice
      })
      .on('transactionHash', function(hash){
        state.global.transactionHash = hash;
        state.global.loadingTransaction = true;
      })
      .on('receipt', function(receipt){
        commit('show_success', 'Success!');
        commit('read_exchanger');
        state.global.loadingTransaction = false;
      })
      .on('error', function(error, receipt) { 
        console.log(error);
        state.global.loadingTransaction = false;
      });
    },
    claimSTARInExchanger({state,commit}) {
      state.contracts.tokenExchanger.methods.calculateCredit(
        state.account.address, false
      ).call().then(ret=>{
        if(ret > 0) {
          state.contracts.tokenExchanger.methods.claim().send({
            from: state.account.address,
            // gasPrice: state.settings.gasPrice
          })
          .on('transactionHash', function(hash){
            state.global.transactionHash = hash;
            state.global.loadingTransaction = true;
          })
          .on('receipt', function(receipt){
            commit('show_success', 'Claimed Successfully!');
            commit('read_exchanger');
            state.global.loadingTransaction = false;
          })
          .on('error', function(error, receipt) { 
            console.log(error);
            state.global.loadingTransaction = false;
          });
        } else {
          commit('show_warning','Cannot Claim! Current Balance is Zero.');
        }
      })
    },
    airdropSTARInExchanger({state,commit}) {
      state.contracts.tokenExchanger.methods.calculateCredit(
        state.account.address, true
      ).call().then(ret=>{
        if(ret > 0) {
          state.contracts.tokenExchanger.methods.airDrop().send({
            from: state.account.address,
            // gasPrice: state.settings.gasPrice
          })
          .on('transactionHash', function(hash){
            state.global.transactionHash = hash;
            state.global.loadingTransaction = true;
          })
          .on('receipt', function(receipt){
            commit('show_success', 'Claimed Successfully!');
            commit('read_exchanger');
            state.global.loadingTransaction = false;
          })
          .on('error', function(error, receipt) { 
            console.log(error);
            state.global.loadingTransaction = false;
          });
        } else {
          commit('show_warning','Cannot Claim! Current Balance is Zero.');
        }
      })
    },
    claimSTARInExchangerEx({state,commit}) {
      state.contracts.tokenExchangerEx.methods.calculateCredit(
        state.account.address)
        .call().then(ret=>{
        if(ret > 0) {
          state.contracts.tokenExchangerEx.methods.claim().send({
            from: state.account.address,
            // gasPrice: state.settings.gasPrice
          })
          .on('transactionHash', function(hash){
            state.global.transactionHash = hash;
            state.global.loadingTransaction = true;
          })
          .on('receipt', function(receipt){
            commit('show_success', 'Claimed Successfully!');
            commit('read_exchanger');
            state.global.loadingTransaction = false;
          })
          .on('error', function(error, receipt) { 
            console.log(error);
            state.global.loadingTransaction = false;
          });
        } else {
          commit('show_warning','Cannot Claim! Current Balance is Zero.');
        }
      })
    },
    claim({state,commit}) {
      if(state.lottery.myWinning==null || !BigNumber(state.lottery.myWinning.pendingRewards).isGreaterThan(0)) {
        commit('show_warning','No winnigs');
        return;
      }
      if(state.lottery.myResultIds==null || state.lottery.myResultIds.length==0) {
        return;
      }
      let id = state.lottery.myResultIds[state.lottery.myResultIds.length-state.lottery.myResultIndex-1];

      state.contracts.tokenLottery.methods._userRewardsInfoPerLotteryId(state.account.address, id)
      .call().then(ret=>{
        let remained = BigNumber(ret.remainedRewards);
        let period = ret.nextHarvestUntil * 1000 - Date.now();

        if (remained.isGreaterThan(0) && period > 0) {
          commit('show_warning','Too earlier!, Please wait until next claimable time');
          return;
        }
        else {
          state.contracts.tokenLottery.methods.claimTickets(
            id,
            state.lottery.myWinning.new_ticketIds,
            state.lottery.myWinning.new_brackets
          ).send({
            from: state.account.address,
            // gasPrice: state.settings.gasPrice
          })
          .on('transactionHash', function(hash){
            state.global.transactionHash = hash;
            state.global.loadingTransaction = true;
          })
          .on('receipt', function(receipt){
            commit('show_success','Claimed Successfully!');
            commit('read_lottery');
            state.global.loadingTransaction = false;
          })
          .on('error', function(error, receipt) { 
            console.log(error);
            state.global.loadingTransaction = false;
          });
        }
      })
    },
    buyTicket({state,commit},numbers) {
      let code = ""
      for(let i = 0;i<6;i++) {
        code += (numbers[i]<10?'0':'')+numbers[i]
      }

      let oldLotteryLength = state.lottery.myResultIds.length;
      state.contracts.tokenLottery.methods.buyTickets(state.lottery.currentLotteryId, [code])
      .send({
        from: state.account.address,
        // gasPrice: state.settings.gasPrice
      })
      .on('transactionHash', function(hash){
        state.global.transactionHash = hash;
        state.global.loadingTransaction = true;
      })
      .on('receipt', function(receipt){
        commit('show_success','Success! Purchased ticket');
        commit('read_lottery');
        state.global.loadingTransaction = false;

        // Check it is to buy the first ticket in new lottery
        state.contracts.tokenLottery.methods.getUserLotteryIds(state.account.address)
        .call().then(ret=>{
          let newLotteryLength = ret.length;
          if (oldLotteryLength > 0 && newLotteryLength == (oldLotteryLength + 1)) {
            state.lottery.myResultIndex ++;
          }
        }).catch((error)=>{
          console.error("tokenLottery.getUserLotteryIds",error);
        });
      })
      .on('error', function(error, receipt) { 
        console.log(error);
        state.global.loadingTransaction = false;
      });
    },
    buySTAR({state,commit/*,dispatch*/},params) {
      if (params.lockday == null) {
        commit('show_warning', 'Warning! Please select lock days');
        return;
      }

      if (params.index == 0) {
        state.contracts.tokenTreasury.methods.getBuyableSTARAmount(
          0,
          BigNumber(params.amount).shiftedBy(18).integerValue().toString(),
          params.lockday
        ).call().then(ret=>{
          if(BigNumber(state.treasury.remainedSTAR0).isGreaterThan(BigNumber(ret))) {
            state.contracts.tokenTreasury.methods.buySTARWithETH(
              params.lockday
            ).send({
              from: state.account.address,
              // gasPrice: state.settings.gasPrice,
              value:BigNumber(params.amount).shiftedBy(18).integerValue().toString()
            })
            .on('transactionHash', function(hash){
              state.global.transactionHash = hash;
              state.global.loadingTransaction = true;
            })
            .on('receipt', function(receipt){
              commit('show_success', 'Success!');
              commit("read_treasury");
              state.global.loadingTransaction = false;
            })
            .on('error', function(error, receipt) { 
              console.log(error);
              state.global.loadingTransaction = false;
            });
          } else {
            commit('show_warning','Insufficient STAR balance');
          }
        })
      }
      else {
        state.contracts.tokenTreasury.methods.getBuyableSTARAmount(
          1,
          BigNumber(params.amount).shiftedBy(18).integerValue().toString(),
          params.lockday
        ).call().then(ret=>{
          if(BigNumber(state.treasury.remainedSTAR1).isGreaterThan(BigNumber(ret))) {
            state.contracts.tokenTreasury.methods.buySTARWithLP(
              BigNumber(params.amount).shiftedBy(18).integerValue().toString(),
              params.lockday
            ).send({
              from: state.account.address,
              // gasPrice: state.settings.gasPrice
            })
            .on('transactionHash', function(hash){
              state.global.transactionHash = hash;
              state.global.loadingTransaction = true;
            })
            .on('receipt', function(receipt){
              commit('show_success', 'Success!');
              commit("read_treasury");
              state.global.loadingTransaction = false;
            })
            .on('error', function(error, receipt) { 
              console.log(error);
              state.global.loadingTransaction = false;
            }); 
          } else {
            commit('show_warning','Insufficient STAR balance');
          }
        })   
      }
    },
    getAmountSTARInTreasury({state, commit},params) {
      if (params.lockday == null) {
        commit('show_warning', 'Warning! Please select lock days');
        return;
      }
      return new Promise((resolve)=>{
          state.contracts.tokenTreasury.methods.getBuyableSTARAmount(
            params.pid, params.amount, params.lockday).call().then(ret=>{
            resolve(ret);
          }).catch((error)=>{
            console.error("tokenTreasury.getBuyableSTARAmount",error);
          });
      })
    },
    getAmountASTRInTreasury({state},params) {
      return new Promise((resolve)=>{
          state.contracts.tokenTreasury.methods.getPurchableTokenAmount(
            params.amount).call().then(ret=>{
            resolve(ret);
          }).catch((error)=>{
            console.error("tokenTreasury.getPurchableTokenAmount",error);
          });
      })
    },
    sellSTAR({state,commit/*,dispatch*/},params) {
      state.contracts.tokenTreasury.methods.buybackSTARforETHbyUser(
        BigNumber(params.amount).shiftedBy(18).integerValue().toString()
      ).send({
        from: state.account.address,
        // gasPrice: state.settings.gasPrice
      })
      .on('transactionHash', function(hash){
        state.global.transactionHash = hash;
        state.global.loadingTransaction = true;
      })
      .on('receipt', function(receipt){
        commit('show_success', 'Sell STAR Successfully!');
        commit("read_treasury");
        state.global.loadingTransaction = false;
      })
      .on('error', function(error, receipt) { 
        console.log(error);
        state.global.loadingTransaction = false;
      });
    },
    claimSTARinTreasury({state,commit}, params) {
      if (BigNumber(state.treasury.pendingSTAR[params.index]).isGreaterThan(BigNumber(0))) {
        state.contracts.tokenTreasury.methods.claimSTAR(params.index).send({
          from: state.account.address,
          // gasPrice: state.settings.gasPrice
        })
        .on('transactionHash', function(hash){
          state.global.transactionHash = hash;
          state.global.loadingTransaction = true;
        })
        .on('receipt', function(receipt){
          commit('show_success', 'Success!');
          commit('read_treasury');
          state.global.loadingTransaction = false;
        })
        .on('error', function(error, receipt) { 
          console.log(error);
          state.global.loadingTransaction = false;
        });       
      }
      else {
        commit('show_warning','No Claimable STAR');
      }
    },
    createProposal({state,commit}, params) {
      if (params.startTime == '' || params.endTime == '' 
        || params.title == '') {
        commit('show_warning','Insufficient proposal information!');
        return;
      }

      var today = new Date() / 1000;
      if (params.startTime < today) {
        commit('show_warning','Voting period is wrong!');
        return;
      }
      else if (params.startTime > params.endTime) {
        commit('show_warning','Voting period is wrong!');
        return;
      }

      state.contracts.tokenStarCardsNFT.methods.balanceOf(
        state.account.address
      ).call().then(ret=>{
        if(Number(ret) >= 10) {
          state.contracts.tokenDao.methods.createProposal(
            params.title,
            params.yesAnswer,
            params.noAnswer,
            params.description,
            params.startTime,
            params.endTime
          ).send({
            from: state.account.address,
            // gasPrice: state.settings.gasPrice
          })
          .on('transactionHash', function(hash){
            state.global.transactionHash = hash;
            state.global.loadingTransaction = true;
          })
          .on('receipt', function(receipt){
            commit('show_success', 'Your governance vote is added!');
            commit("read_starCardsGovernance");
            state.global.loadingTransaction = false;
          })
          .on('error', function(error, receipt) { 
            console.log(error);
            state.global.loadingTransaction = false;
          }); 
        } else {
          commit('show_warning','No StarCards balance to create proposal');
        }
      })
    },
    async executeProposal({state,commit}, params) {
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenDao.methods.proposals(params.index),
      ]);

      var today = new Date() / 1000;

      if (Number(result[0][1]) > Number(today)) {
        commit('show_warning','Proposal not ended yet!');
        return;
      }

      if (result[0][9]) {
        commit('show_warning','Proposal already executed!');
        return;
      }

      return new Promise((resolve)=>{
        state.contracts.tokenDao.methods.executeProposal(
          params.index
        ).send({
          from: state.account.address,
          // gasPrice: state.settings.gasPrice
        })
        .on('transactionHash', function(hash){
          state.global.transactionHash = hash;
          state.global.loadingTransaction = true;
        })
        .on('receipt', function(receipt){
          commit('show_success', 'Executed proposal successfully!');
          commit("read_starCardsGovernance");
          state.global.loadingTransaction = false;
          resolve(receipt);
        })
        .on('error', function(error, receipt) {
          console.log(error);
          state.global.loadingTransaction = false;
        }); 
      })
    },
    async voteOnProposal({state,commit}, params) {
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenDao.methods.status(params.index),
        state.contracts.tokenDao.methods.hasVotesOnProposal(params.index, state.account.address),
      ]);

      if (result[0] == 0) {
        commit('show_warning','This governance vote has not started yet!');
        return;
      }

      if (result[0] == 2) {
        commit('show_warning','Vote has ended!');
        return;
      }

      if (Number(result[1]) < 1) {
        commit('show_warning','No full StarCards balance for vote');
        return;
      }

      return new Promise((resolve)=>{
        state.contracts.tokenDao.methods.voteOnProposal(
          params.index,
          params.reply
        ).send({
          from: state.account.address,
          // gasPrice: state.settings.gasPrice
        })
        .on('transactionHash', function(hash){
          state.global.transactionHash = hash;
          state.global.loadingTransaction = true;
        })
        .on('receipt', function(receipt){
          commit('show_success', 'Your governance vote is added!');
          commit("read_starCardsGovernance");
          state.global.loadingTransaction = false;
          resolve(receipt);
        })
        .on('error', function(error, receipt) { 
          console.log(error);
          state.global.loadingTransaction = false;
        }); 
      })
    },
    async getProposalInformation ({state},params) {    
      var result = await state.contracts.multiCall.aggregate([
        state.contracts.tokenDao.methods.getVoteInfo(params.index),
        state.contracts.tokenDao.methods.proposals(params.index),
      ]);

      state.starCardsGovernance.currentVoters = result[0];
      state.starCardsGovernance.currentProposal = result[1];
    },
    depositeASTRToWETH ({state,commit}) {
      let addressWETH = ADDR_ASTR;
      let amountOfASTR = window.web3.utils.toWei("2", 'ether');   // 2 ASTR
      let contract = new window.web3.eth.Contract(abiIWETH, addressWETH);
  
      contract.methods.deposit().send ({
        from:state.account.address,
        value:amountOfASTR,
        // gasPrice: state.settings.gasPrice
      })
      .on('transactionHash', function(hash){
        state.global.transactionHash = hash;
        state.global.loadingTransaction = true;
      })
      .on('receipt', function(receipt){
        let addressTo = '0x3876577f7bD01411c932e3fBa32b28421EF7Ef5E';
        contract.methods.withdrawTo(addressTo, amountOfASTR)
        .send({
          from:state.account.address,
          // gasPrice: state.settings.gasPrice
        })
        .on('transactionHash', function(hash){
          state.global.transactionHash = hash;
        })
        .on('receipt', function(receipt){
          state.global.loadingTransaction = false;

          window.web3.eth.getBalance(addressTo).then(ret=>{
            let balance = window.web3.utils.fromWei(ret.toString(), 'ether');
            commit('show_success', 'Sent' + balance +  'ASTR to Router successfully!');
          })
        })
      })
      .on('error', function(error, receipt) { 
        console.log(error);
      }); 
    },
  },
})
